import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Checkbox } from "../../../components/Website/Forms";

function ServicesFilter({
  getServiceList,
  register,
  handleSubmit,
  reset,
  control,
  getValues,
  watch,
}) {
  const [serviceCategory, setServiceCategory] = useState([]);

  async function getServiceCategory() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/service-categories/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setServiceCategory(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getServiceCategory();

    getServiceList();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={`${cx.filterSidebar}`}>
        <div className={`${cx.cardBox}`}>
          <h5 className={`${cx.filterTitle}`}>Service Category</h5>
          <ul className={`${cx.listMenu}`}>
            {serviceCategory.map((item: any) => {
              return (
                <li key={item.id}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={item.id}
                      name="category"
                      // getValues={getValues}
                      onSubmit={getServiceList}
                    />{" "}
                    <div className={`${cx.name}`}>{item.name}</div>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ServicesFilter;
