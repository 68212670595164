import React, { useRef } from "react";
import m from "./Modal.module.scss";
import { Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logoutandChangeHeader } from "../../redux_toolkit/reducer/registrationReducer";
import { getAuth, signOut } from "firebase/auth";
import useToast from "../../utils/useToast";

const Logout = (props: any) => {
  let { show, handleLogoutClose } = props;
  const logoutLinkRef = useRef(null);
  const dispatch = useDispatch();
  const auth = getAuth();
  const { showToast } = useToast();

  const handleLogout = () => {
    if (JSON.parse(localStorage.getItem("userData")!)?.provider) {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    }
    localStorage.clear();
    showToast({ type: "success", message: "Logged Out Successfully" });
    handleLogoutClose();
    dispatch(logoutandChangeHeader());
    logoutLinkRef.current.click();

    // setTimeout(() => {
    //   navigate("/");
    // }, 3000);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={handleLogoutClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleLogoutClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h3 className={`text-center`}>Are you sure you want to logout?</h3>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleLogoutClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`btn ${m.actionBtn}`}
            onClick={handleLogout}
          >
            Logout
          </button>
          <a ref={logoutLinkRef} href="/" style={{ display: "none" }}>
            Go to login
          </a>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Logout;
