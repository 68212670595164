import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination as SwiperPagination, Autoplay } from "swiper/modules";
import { allBgBanner } from "../../../assets/images";

function SwiperCarousel({
  handleSlideChange,
  heroBannerAds,
  ActiveHeroBannerIndex,
  upsideDownHeader,
}) {
  return (
    <section className={`${st.create_register}`}>
      <Container fluid className="p-0">
        <Row>
          <Col md={12}>
            <Swiper
              className="mySwiper swiper-h"
              slidesPerView={1}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[SwiperPagination, Autoplay]}
              onSlideChange={handleSlideChange}
            >
              <SwiperSlide>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
              </SwiperSlide>
              {heroBannerAds?.map(({ id, title, url, image }) => {
                return (
                  <SwiperSlide>
                    <NavLink to={url} target="_blank" key={id}>
                      <div className="item">
                        <div className="m-0">
                          <img src={image} alt="aid" />
                        </div>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div
              className={`${st.main_heading} ${
                ActiveHeroBannerIndex === 0 ? st.slider_content : ""
              }`}
            >
              <h1>{upsideDownHeader}</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SwiperCarousel;
