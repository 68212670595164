import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Button, Row } from "react-bootstrap";
import { Container, Col, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { google, facebook, microsoft, yahooIcon } from "../../../assets/images";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  OAuthProvider,
  signInWithRedirect,
  FacebookAuthProvider,
  getAuth
} from "firebase/auth";
import { auth } from "../../../firebase";
import { loginandChangeHeader } from "../../../redux_toolkit/reducer/registrationReducer";
import { useDispatch } from "react-redux";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";

auth.languageCode = "it";
// To apply the default browser preference instead of explicitly setting it.

const Login = () => {
  const { showToast } = useToast();

  const [user, setUser] = useState<any>({});
  const [loginData, setLoginData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      let isEmail = /\S+@\S+\.\S+/.test(loginData);
      const formdata = new FormData();
      if (isEmail) {
        formdata.append("email", loginData);
      } else {
        formdata.append("phoneNo", "+91" + loginData);
      }

      const requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch(`${process.env.REACT_APP_BASEURL}/login/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          navigate("/otp", {
            state: { [isEmail ? "email" : "phone"]: loginData },
          });
        })
        .catch((error) => {
          showToast({ type: "error", message: "Something went wrong!" });
          console.error(error);
        });
    } catch (error) {
      console.error("Error logging in:", error);
      showToast({ type: "error", message: "Something went wrong!" });
    }
  };

  // const logOut = () => {
  //   signOut(auth);
  // };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      setUser(currentUser);
      setTimeout(() => {
        if (localStorage.getItem("provider") == "yahoo" && currentUser) {
          providerSSOLoginSignup({
            provider: "yahoo",
            firstName: currentUser && currentUser?.displayName?.split(" ")[0],
            lastName: currentUser && currentUser?.displayName?.split(" ")[1],
            email: currentUser && currentUser?.email,
            sso_profile_image: currentUser && currentUser?.photoURL,
            access_token: currentUser.accessToken,
          });
        }
      }, 2000);
    });
    return () => unsubscribe();
  }, []);

  async function providerSSOLoginSignup({
    provider,
    firstName,
    lastName,
    email,
    sso_profile_image,
    access_token,
  }) {
    try {
      const formdata = new FormData();
      formdata.append("provider", provider);
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("email", email);
      formdata.append("sso_profile_image", sso_profile_image);
      formdata.append("access_token", access_token);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/sso_login_signup/`,
        {
          method: "POST",
          body: formdata,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        localStorage.setItem("token", responseData.token.access);
        localStorage.setItem("userData", JSON.stringify(responseData?.data));
        showToast({ type: "success", message: "LoggedIn Successfully" });
        setTimeout(() => {
          dispatch(loginandChangeHeader());
          navigate("/");
        }, 5000);
      } else {
        const errorData = await response.json();
        showToast({ type: "error", message: errorData?.msg });
      }
    } catch (error) {
      console.error("Error logging in:", error);
      showToast({ type: "error", message: "Something went wrong!" });
    }
  }

  function handleGoogleSignIn() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;

        providerSSOLoginSignup({
          provider: "google",
          firstName: user && user?.displayName?.split(" ")[0],
          lastName: user && user?.displayName?.split(" ")[1],
          email: user && user?.email,
          sso_profile_image: user && user?.photoURL,
          access_token: token,
        });
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error: any) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        showToast({ type: "error", message: "Something went wrong!" });
        // ...
      });
  }

  function handleFacebookSignIn() {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(credential,"sdjalkdjjslkjjlkj")
        // The signed-in user info.
        const user = result.user;
        console.log(user,"kjshdkjhakjfhkjdhkhajshdkhkafhsdhfkahfdhjahkdfhhasdhkahdkj")

        // providerSSOLoginSignup({
        //   provider: "facebook",
        //   firstName: user && user?.displayName?.split(" ")[0],
        //   lastName: user && user?.displayName?.split(" ")[1],
        //   email: user && user?.email,
        //   sso_profile_image: user && user?.photoURL,
        //   access_token: token,
        // });
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error: any) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        console.log(credential,"credentials credentials")
        showToast({ type: "error", message: "Something went wrong!" });
        // ...
      });
  }

  async function getMicrosoftUserProfile(accessToken: string) {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const response = await fetch(`https://graph.microsoft.com/v1.0/me`, {
        method: "GET",
        headers: myHeaders,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error);
    }
  }

  function handleMicrosoftSignIn() {
    const provider = new OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      login_hint: "mohit.appic@gmail.com",
      tenant: "f8cdef31-a31e-4b4a-93e4-5f571e91255a", // Put Tenant Id from Azure registered app,
      prompt: "consent", // Get Consent from user to access their basic info (optional - Reommended only during SignUp)
    });
    return signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        getMicrosoftUserProfile(accessToken);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error: any) => {
        showToast({ type: "error", message: "Something went wrong!" });
        // ...
      });
  }
  // async function getYahooUserProfile(accessToken: string) {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${accessToken}`);

  //     const response = await fetch(
  //       `https://api.login.yahoo.com/openid/v1/userinfo`,
  //       {
  //         method: "GET",
  //         mode: "no-cors",
  //         headers: myHeaders,
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to submit the data. Please try again.");
  //     }

  //     // Handle response if necessary
  //     const data = await response.json();
  //     // ...
  //   } catch (error) {
  //     // Capture the error message to display to the user
  //     console.error(error);
  //   }
  // }

  async function handleYahooSignIn() {
    const provider = new OAuthProvider("yahoo.com");
    localStorage.setItem("provider", "yahoo");
    await signInWithRedirect(auth, provider);
  }
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Login</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={6} md={9} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <h3>Login</h3>
                {/* <p> Lorem  </p> */}

                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleGoogleSignIn}
                >
                  <img src={google} alt="google" /> Sign in with Google
                </button>

                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleFacebookSignIn}
                >
                  <img src={facebook} alt="facebook" /> Sign in with Facebook
                </button>
                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleMicrosoftSignIn}
                >
                  {" "}
                  <img src={microsoft} alt="microsoft" /> Sign in with Microsoft
                </button>
                <button
                  className="btn mt-3"
                  type="button"
                  onClick={handleYahooSignIn}
                >
                  <img src={yahooIcon} alt="yahoo" /> Sign in with Yahoo
                </button>

                <div className={`${cx.line_box}`}>
                  <div className={`${cx.line}`}></div>
                  <div className={`${cx.line_content}`}>
                    or sign in with Phone No.
                  </div>
                </div>

                <Form>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>
                      Phone No. / E-mail<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email or phone number"
                      value={loginData}
                      onChange={(e) => setLoginData(e.target.value)}
                    />
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="password" placeholder="Your Password" />
                  </Form.Group>

                  <div className={`${cx.forget_checks} mb-4`}>
                    <div className={`${cx.left}`}>
                      <Form.Check
                        type="checkbox"
                        id="checkbox"
                        label="Remember Me"
                      />
                    </div>
                    <div className={`${cx.right}`}>
                      <NavLink to={"#"}>Forgot Password?</NavLink>
                    </div>
                  </div> */}

                  <div className={`${cx.submitBtn} mb-3`}>
                    <Button type="button" className="btn" onClick={onSubmit}>
                      Login
                    </Button>
                    {/* <NavLink className="btn" to={"/otp"} onClick={}>Login</NavLink> */}
                  </div>

                  <div className={`${cx.not_Register}`}>
                    <span>Not registered?</span>&nbsp;
                    <NavLink to={"/CreateAccount"}>Create an Account</NavLink>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
