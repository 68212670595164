import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col, Button } from "react-bootstrap";
import { CiMail } from "react-icons/ci";
import { FaKey } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import { NavLink } from "react-router-dom";

const ForgotPassword = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <div className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={5} md={6} sm={12} className={`m-auto`}>
              <div className={`${cx.onboardingBox}`}>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <span>
                    <FaKey />
                  </span>
                  <h1>Forgot Password?</h1>
                  <p>No worries, we'll send you quickspares.</p>
                </div>

                <div className={`${cx.formStart}`}>
                  <div className={`mb-3 position-relative ${cx.inputIcon}`}>
                    <CiMail />
                    <input
                      className={`form-control`}
                      type="email"
                      placeholder="Email Address"
                    />
                  </div>

                  <div className={`${cx.onboardingButton}`}>
                    <Button className={`btn`}>Reset Password</Button>
                  </div>

                  <p>
                    <NavLink to="/login">
                      {" "}
                      <IoIosArrowRoundBack /> Back To Login
                    </NavLink>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
