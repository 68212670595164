import React from "react";
import cx from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = (props: any) => {
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>

      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`} style={{ transform: 'translate(0%, -260%)' }}>
                <h1 style={{ maxWidth: '1320px', marginLeft: 'auto', marginRight: 'auto', padding: '0px 6px' }}>Privacy Policy</h1>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.contentPage}`}>
        <Container>
          <Row>
            <Col md="10" sm="12">
              <div className={`${cx.content}`}>
                <h2>Utilisation et Traitement des Données Personnelles</h2>
                <p>
                  En accédant aux services numériques proposés par <NavLink to={"/"}> <strong>CongoClick.com</strong> </NavLink>,
                  l'utilisateur consent de manière expresse, libre et éclairée à
                  ce que ses données personnelles soient collectées, traitées,
                  exploitées, et éventuellement transférées, conformément aux
                  stipulations énoncées dans les présentes Conditions Générales
                  et en stricte conformité avec les dispositions légales en vigueur,
                  notamment celles prévues par <strong>la Loi n° 20/017 du 25 novembre 2020
                    relative aux télécommunications et technologies de l'information et
                    de la communication.</strong> Cet engagement s’inscrit dans un cadre respectueux
                  des droits fondamentaux liés à la protection des données à caractère personnel.
                </p>

                <h3>Finalités du traitement des données</h3>
                <p>Les informations à caractère personnel collectées auprès des utilisateurs sont susceptibles d’être utilisées dans le cadre des objectifs suivants :</p>

                <ul>
                  <li>
                    <h5>Assurer la fourniture de services :</h5>
                    <p>Veiller à l'optimisation du fonctionnement des fonctionnalités inhérentes au site, incluant l’accès aux contenus personnalisés et la formulation de recommandations adaptées aux préférences des utilisateurs.</p>
                  </li>
                  <li>
                    <h5>Conduire des analyses stratégiques :</h5>
                    <p>
                      Procéder à des études statistiques, des analyses techniques ou comportementales en vue d’affiner et d’enrichir l’expérience utilisateur dans une démarche continue d’amélioration des services.
                    </p>
                  </li>
                  <li>
                    <h5>Engager des démarches de prospection commerciale :</h5>
                    <p>
                      Émettre des communications à caractère publicitaire, informatif ou promotionnel, relatives à des produits ou services susceptibles de susciter l’intérêt des utilisateurs.
                    </p>
                  </li>
                  <li>
                    <h5>Faciliter le partage des données avec des tiers :</h5>
                    <p>
                      Sous réserve de l’observance des normes légales en vigueur, transmettre des données personnelles aux partenaires commerciaux, sous-traitants ou autres tiers dans le cadre d’objectifs compatibles avec ceux explicités ci-dessus.
                    </p>
                  </li>
                </ul>

                <h3>Cession des données à des tiers</h3>
                <p>L’utilisateur concède expressément que ses données personnelles, qu’elles soient anonymisées ou non, peuvent être transférées ou cédées aux entités tierces suivantes :
                </p>

                <ul>
                  <li>Partenaires commerciaux ou annonceurs pour la réalisation d’études de marché et d’analyses statistiques ;</li>
                  <li>Organismes de commercialisation d’espaces publicitaires pour des campagnes ciblées ;</li>
                  <li>Collaborateurs engagés dans des initiatives communes visant à proposer des produits ou services complémentaires aux utilisateurs.</li>
                </ul>
                <p>Dans des cas spécifiques, ces données pourront faire l’objet d’une <strong>cession commerciale</strong> à des tiers, sous réserve que ces derniers adhèrent aux principes de protection des données édictés par la réglementation applicable. Les utilisateurs seront préalablement informés de tout transfert dans la mesure où la loi l’exige.</p>

                <h3>Consentement éclairé</h3>
                <p>L’utilisateur dispose du droit irrévocable de révoquer, à tout moment, son consentement au traitement de ses données personnelles en adressant une requête à l’attention du <strong>Délégué à la Protection des Données (DPO)</strong> à l’adresse suivante : <a href="mailto:Congoclickwebsite@gmail.com"><strong>Congoclickwebsite@gmail.com</strong> </a>. Cette révocation ne saurait toutefois avoir d’effet rétroactif sur les traitements opérés avant sa notification.</p>

                <h3>Droits des utilisateurs</h3>
                <p>En application des dispositions de la législation congolaise relative à la protection des données à caractère personnel, les utilisateurs jouissent des droits suivants :</p>

                <ul>
                  <li><strong>Droit d’accès : </strong> Obtenir confirmation que leurs données personnelles font l’objet d’un traitement et en recevoir copie.</li>
                  <li><strong>Droit de rectification : </strong> Demander la correction ou la mise à jour de leurs données inexactes ou incomplètes.</li>
                  <li><strong>Droit d’opposition : </strong> Refuser, pour des motifs légitimes, le traitement de leurs données, notamment à des fins commerciales.</li>
                  <li><strong>Droit d’effacement : </strong> Solliciter la suppression de leurs données personnelles dans les conditions prévues par la réglementation.</li>
                </ul>

                <h3>Clause de limitation de responsabilité</h3>
                <p><NavLink to={"/"}> <strong>CongoClick.com</strong> </NavLink> décline toute responsabilité en cas d’usage détourné ou abusif des données personnelles par des tiers qui en auraient légalement obtenu l’accès. Cette limitation s’applique tant que lesdits tiers respectent les obligations légales et contractuelles leur incombant.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
