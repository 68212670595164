import React, { useEffect } from "react";
import cx from "./index.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col, Button, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { uploadIcon } from "../../../assets/images";
import { useForm } from "react-hook-form";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";

const Signup = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { showToast } = useToast();

  const onSubmit = async (data: any) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("firstName", data?.firstName);
      formData.append("lastName", data?.lastName);
      formData.append("userName", data?.userName);
      formData.append("apartmentNumber", data?.apartmentNumber);
      formData.append("streetName", data?.streetName);
      formData.append("city", data?.city);
      formData.append("state", data?.state);
      formData.append("provider", "credentials");
      formData.append("uploadImages", data?.uploadImages[0]);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/login_signup/user_data_register/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // const responseData = await response.json();
        showToast({
          type: "success",
          message: "Signup successfull!",
        });
        localStorage.removeItem("token");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        showToast({ type: "error", message: "Something went wrong!" });
        console.error("Failed to submit form");
      }
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error, "Error submitting form:");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Create An Account</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={11} sm={12} className={`m-auto`}>
              <div className={`${cx.formBox}`}>
                <h2>Profile Setup</h2>
                {/* <h3>Login</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Condimentum sit donec
                  pel enim
                </p> */}
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your name"
                          {...register("firstName", { required: true })}
                        />
                        {errors.firstName && (
                          <span className="text-danger">
                            First name is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Last Name"
                          {...register("lastName", { required: true })}
                        />
                        {errors.lastName && (
                          <span className="text-danger">
                            Last name is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Username"
                          {...register("userName", { required: true })}
                        />
                        {errors.userName && (
                          <span className="text-danger">
                            Username is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {/* <Form.Group controlId="formFile" className="upload_img mt-md-4 pt-md-2"> */}
                      {/* <Form.Label>
                          <img src={uploadIcon} className="uploadIcon" alt="upload" />
                          Upload Images
                          <Form.Control type="file" /> */}

                      <Form.Group
                        controlId="formFile"
                        className="upload_img mt-md-4 pt-md-2"
                      >
                        <Form.Label>
                          <img
                            src={uploadIcon}
                            className="uploadIcon"
                            alt="upload"
                          />
                          Upload Images
                          <Form.Control
                            type="file"
                            {...register("uploadImages", { required: true })}
                          />
                        </Form.Label>
                        {errors.image && (
                          <span className="text-danger">Image is required</span>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col md={12}>
                      <h3>Address</h3>
                    </Col> */}
                    {/* <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Apartment Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Apartment Number"
                          {...register("apartmentNumber", { required: true })}
                        />
                        {errors.apartmentNumber && (
                          <span className="text-danger">Apartment number is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    {/* <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Street Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Street Name"
                          {...register("streetName", { required: true })}
                        />
                        {errors.streetName && (
                          <span className="text-danger">Street name is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your City"
                          {...register("city", { required: true })}
                        />
                        {errors.city && (
                          <span className="text-danger">
                            City is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your State"
                          {...register("state", { required: true })}
                        />
                        {errors.state && (
                          <span className="text-danger">
                            State is required
                            <span className="text-danger">*</span>
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    {/* <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Zip Code"
                          {...register("zipCode", { required: true })}
                        />
                        {errors.zipCode && (
                          <span className="text-danger">Zip code is required<span className="text-danger">*</span></span>
                        )}
                      </Form.Group>
                    </Col> */}
                    <div className="col-md-2 mb-2">
                      <Button
                        className="btn save"
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                      {/* <NavLink to={"/"} className="btn save" >Submit</NavLink> */}
                    </div>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/CreateAccount"} className="btn cancel">
                        Back
                      </NavLink>
                    </div>
                  </Row>
                </Form>
                {/* <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="Your name" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="name"
                          placeholder="Your Last Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.Controlname2"
                      >
                        <Form.Label>User Name</Form.Label>
                        <Form.Control type="text" placeholder="Your Username" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId="formFile"
                        className="upload_img mt-md-4 pt-md-2"
                      >
                        <Form.Label>
                          <img
                            src={uploadIcon}
                            className="uploadIcon"
                            alt="upload"
                          />{" "}
                          Upload Images
                          <Form.Control type="file" />
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <h3>Address</h3>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>Apartment Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your Apartment Number"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.inputname2"
                      >
                        <Form.Label>Street Name</Form.Label>
                        <Form.Control
                          type="Text"
                          placeholder="Your Street Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="Your City" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="Your State" />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.input1"
                      >
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="text" placeholder="Your Zip Code" />
                      </Form.Group>
                    </Col>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/"} className="btn save" >Submit</NavLink>
                    </div>
                    <div className="col-md-2 mb-2">
                      <NavLink to={"/CreateAccount"} className="btn cancel">Back</NavLink>
                    </div>
                  </Row>
                </Form> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Signup;
