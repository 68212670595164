import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container, Col } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";

const ServicePostForm = () => {
  const navigate = useNavigate();
  const [serviceCategory, setServiceCategory] = useState([]);
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];

  async function getServiceCategory() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/service-categories/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data }: any = await response.json();
      const options = data.map((item: { id: number; name: string }) => ({
        value: item?.id,
        label: item?.name,
      }));
      setServiceCategory(options);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const onSubmit = async ({
    service_name,
    category,
    price,
    description,
  }: any) => {
    try {
      const user_id = JSON.parse(localStorage.getItem("userData")!).id;
      const formData = new FormData();
      formData.append("name", service_name);
      formData.append("price", price);
      formData.append("category", category.value);
      formData.append("description", description);
      formData.append("user", user_id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/services/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      if (response.status === 201) {
        reset();
        showToast({
          type: "success",
          message: "Service Created Successfully!",
        });
        sendTelegramLink(data?.id);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  async function sendTelegramLink(id: number) {
    const formData = new FormData();
    formData.append("name", id.toString());
    const response = await fetch(
      `${process.env.REACT_APP_BASEURL}/service/service-link/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")!}`,
        },
        body: formData,
      }
    );
    if (!response.ok) {
      showToast({ type: "error", message: "Something went wrong!" });
      throw new Error("Failed to send the telegram link. Please try again.");
    }
    const data = await response.json();
    if (response.status === 200) {
      reset();
      setTimeout(() => {
        navigate(`/services-details/${id}`);
      }, 1000);
    }
  }

  useEffect(() => {
    getServiceCategory();
    window.scrollTo(0, 0);
  }, []);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Post A Service</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={9} md={11} className="m-auto">
              <div className={`${cx.product_mainbox}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.form_heading}`}>
                        <h3>Service Details</h3>
                      </div>
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        Choose Category:
                      </label>
                      <Controller
                        name="category"
                        control={control}
                        rules={{
                          required: "This field is required",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable={false}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={serviceCategory}
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                          />
                        )}
                      />
                      {Object.keys(errors).includes("category") && (
                        <p style={{ color: "red" }}>This Field is Required</p>
                      )}
                    </Col>
                    <Col md={4} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("service_name", { required: true })}
                      />
                      {errors.service_name && (
                        <span>This field is required</span>
                      )}
                    </Col>
                    <Col md={4} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>City :</label>
                      <input className="form-control" type="text" />
                      {/* {errors.price && <span>This field is required</span> */}
                    </Col>
                    <Col md={4} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Price :</label>
                      <div className="position-relative">
                        <input
                          className="form-control"
                          type="number"
                          {...register("price", { required: true })}
                          style={{ paddingRight: "81px" }}
                        />
                        <div className={`${cx.priceSignBox}`}>$/ heure</div>
                      </div>
                      {errors.price && <span>This field is required</span>}
                    </Col>

                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Description:</label>
                      {/* <textarea className="form-control" style={{ minHeight: '80px' }}></textarea> */}
                      <Controller
                        name="description"
                        control={control}
                        rules={{
                          required: "Please enter task description",
                        }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            placeholder={"Write Description"}
                            modules={modules}
                            formats={formats}
                            onChange={(text) => {
                              field.onChange(text);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.nextBack_btn}`}>
                        <button className="btn" type="submit">
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ServicePostForm;
