import React from "react";

interface CheckboxOptionProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: () => void;
}

const CheckboxOption: React.FC<CheckboxOptionProps> = ({
  label,
  value,
  checked,
  onChange,
}) => (
  <div>
    <input
      type="checkbox"
      id={value}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={value}>{label}</label>
  </div>
);

export default CheckboxOption;
