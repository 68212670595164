import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

interface DeletePopupModalProps {
  show: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
  getProductList: () => void;
  id: string | number; // The unique identifier for the item to delete
  apiUrl: string; // API endpoint for the deletion
  title?: string;
  message?: string;
}

const DeletePopupModal: React.FC<DeletePopupModalProps> = ({
  show,
  onClose,
  onDeleteSuccess,
  getProductList,
  id,
  apiUrl,
  title = "Confirm Deletion",
  message = "Are you sure you want to delete this item?",
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setIsDeleting(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete item. Please try again.");
      }

      onDeleteSuccess(); // Notify parent about successful deletion
      onClose(); // Close the modal
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsDeleting(false);
      getProductList();
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        {error && <div className="text-danger mt-3">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isDeleting}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={isDeleting}>
          {isDeleting ? (
            <Spinner as="span" animation="border" size="sm" />
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePopupModal;
