import React from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import FilterSidebar from "../Website/FilterSidebar";

function FilterToggle(props: any) {
  let {
    show,
    handleFilterToggleClose,
    register,
    control,
    onSubmit,
    setValue,
    watch,
    getValues,
    storedSearch,
    categoryList,
    subCategoryList,
    getSubCategoryList,
    dispatch,
  } = props;
  return (
    <>
      <Modal
        centered
        show={show}
        scrollable
        onHide={handleFilterToggleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            onClick={handleFilterToggleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div>
                <FilterSidebar
                  register={register}
                  control={control}
                  onSubmit={onSubmit}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  dispatch={dispatch}
                  storedSearch={storedSearch}
                  categoryList={categoryList}
                  subCategoryList={subCategoryList}
                  getSubCategoryList={getSubCategoryList}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
                    <button
                        type="button"
                        className={`btn ${m.cancelBtn}`}
                        onClick={handleFilterToggleClose}
                    >
                        Clear All
                    </button>
                    <button
                        type="button"
                        className={`btn ${m.actionBtn}`}
                    >
                        Save
                    </button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default FilterToggle;
