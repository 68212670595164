import React, { useRef } from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { profile } from "../../assets/images";
import st from "../../../src/assets/stylesheet/style.module.scss";

const ServiceGetContact = (props: any) => {
  let { show, handleServiceGetContactClose } = props;;


  return (
    <>
      <Modal
        centered
        size="sm"
        show={show}
        onHide={handleServiceGetContactClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Get Contact</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleServiceGetContactClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div className={`${m.getContact_modal}`}>
                <div className={`${m.profileBox}`}>
                  <div className={`${m.left}`}>
                    <img src={profile} alt="user-review" />
                  </div>
                </div>

                <Row>
                  <Col md={12} className={`${st.formBox}`} style={{marginBottom:'12px'}}>
                    <label className={`${st.labelTitle}`}>Posted By:</label>
                    <div className="form-control" style={{lineHeight:'14px'}}>Mosindoc David</div>
                  </Col>
                  <Col md={12} className={`${st.formBox}`} style={{marginBottom:'12px'}}>
                    <label className={`${st.labelTitle}`}>E-mail:</label>
                    <div className="form-control" style={{lineHeight:'14px'}}>mosindoc@gmail.com</div>
                  </Col>
                  <Col md={12} className={`${st.formBox}`} style={{marginBottom:'12px'}}>
                    <label className={`${st.labelTitle}`}>Phone No.:</label>
                    <div className="form-control" style={{lineHeight:'14px'}}>9876543210</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            type="button"
            className={`btn ${m.cancelBtn}`}
            onClick={handleServiceGetContactClose}
          >
            Cancel
          </button>

        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ServiceGetContact;
