import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Nav, Row, Tab } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import OffersCard from "../../../components/Website/OffersCard";
import { useLocation } from "react-router-dom";
import SoldProductCard from "../../../components/Website/SoldProductCard";
import { ProductItem } from "../../../constants/Interface";
import useToast from "../../../utils/useToast";
import DeletePopupModal from "../../../components/Modals/DeletePopupModel";
import jobcx from "../Job/index.module.scss";
import tendercx from "../Tender/index.module.scss";
import servicecx from "../Services/index.module.scss";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { BsPostcard } from "react-icons/bs";
import { TbIdBadge } from "react-icons/tb";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import JobCard from "../../../components/Website/JobCard/JobCard";
import TenderTable from "../../../components/Website/TenderTable/TenderTable";

import ServiceCard from "../../../components/Website/ServiceCard/ServiceCard";
import { MdOutlineHomeRepairService } from "react-icons/md";

const MyOffers = () => {
  const location = useLocation();
  const [productList, setProductList] = useState<ProductItem[]>([]);
  const { showToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const handleOpenModal = (id: number) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const handleDeleteSuccess = () => {
    console.log(`Item with ID ${selectedId} deleted successfully.`);
    // Refresh data or perform other actions as needed
  };
  console.log(location, "location");

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductList();
  }, []);

  const getProductList = async (values?: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/?user_id=${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setProductList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  async function markAsSold(id) {
    try {
      const formData = JSON.stringify({
        sold: true,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTimeout(() => {
        getProductList();
      }, 1000);
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function deleteMyOffer(id) {
    try {
      const formData = JSON.stringify({
        sold: true,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTimeout(() => {
        getProductList();
      }, 1000);
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const [jobList, setJobList] = useState([]);
  const [tenderList, setTenderList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  // const [productList, setProductList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { showToast } = useToast();

  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      // tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      const {
        favourite_jobs,
        favourite_news,
        favourite_tenders,
        favourite_products,
        favourite_services,
      } = await response.json();

      setJobList(favourite_jobs);
      setTenderList(favourite_tenders);
      setNewsList(favourite_news);
      setProductList(favourite_products);
      setServiceList(favourite_services);
      // ...
    } catch (error: any) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error);
    }
  }

  useEffect(() => {
    // getProfile();
    window.scrollTo(0, 0);
  }, []);

  const pathname = location.pathname;

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.offers_Section} ${cx.pageSpace}`}>
        <Container>
          <Col md={12}>
            <div className={`${cx.main_heading} text-center mb-2 pb-1`}>
              <h2>
                {location?.pathname === "/offers"
                  ? "My Offers"
                  : "My Sold Product"}
              </h2>
            </div>
          </Col>
          <Tab.Container id="left-tabs-product" defaultActiveKey="myProduct">
            <Row>
              <Col sm={12}>
                <div className={`${cx.topOfferTabs} text-center mb-4`}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="myProduct">My Offers</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="soldProduct">Sold Product</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="myProduct">
                    <div className={`${cx.slideMain_box}`}>
                      <div className={`${cx.left}`}>
                        <Tab.Container
                          id="left-tabs-examplefav"
                          defaultActiveKey="favFirst"
                        >
                          <Row>
                            <Col md={12}>
                              <Nav>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="favFirst"
                                    className={`${cx.boxes}`}
                                  >
                                    <BsPostcard />
                                    <p>Post</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="favService"
                                    className={`${cx.boxes}`}
                                  >
                                    <MdOutlineHomeRepairService />
                                    <p>Services</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="favSecond"
                                    className={`${cx.boxes}`}
                                  >
                                    <HiBuildingOffice2 />
                                    <p>Jobs</p>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="favThird"
                                    className={`${cx.boxes}`}
                                  >
                                    <TbIdBadge />
                                    <p>Tenders</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Col md={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="favFirst">
                                  {/* <div>
                                    <div
                                      className={`${cx.favouriteSection} ${cx.pageSpace}`}
                                    >
                                      <Row>
                                        {productList.map((product) => (
                                          <Col lg={4} md={6} className="mb-4">
                                            <ProductCard
                                              getProfile={getProfile}
                                              favList={productList}
                                              product={product}
                                              getProductList={getProfile}
                                            />
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  </div> */}
                                  <Row className="mt-3">
                                    {productList
                                      ?.filter((item: any) => item)
                                      ?.filter((item: any) => !item.sold)
                                      ?.map((product: any, index: number) => (
                                        <Col lg={6} md={12} key={index}>
                                          <OffersCard
                                            pathname={pathname}
                                            productData={product}
                                            getProductList={getProductList}
                                            markAsSold={markAsSold}
                                            handleDelete={handleOpenModal}
                                          />
                                        </Col>
                                      ))}
                                  </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="favService">
                                  <div className={`${cx.pageSpace}`}>
                                    <Col lg={9} md={8}>
                                      <Row>
                                        <ServiceCard
                                          pathname={pathname}
                                          cx={servicecx}
                                          serviceList={serviceList}
                                          getServiceList={getProfile}
                                        />
                                      </Row>
                                    </Col>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="favSecond">
                                  <div className={`${cx.pageSpace}`}>
                                    <Col lg={9} md={8}>
                                      <Row>
                                        <JobCard
                                          pathname={pathname}
                                          cx={jobcx}
                                          jobList={jobList}
                                          getJobList={getProfile}
                                        />
                                      </Row>
                                    </Col>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="favThird">
                                  <div className={`${cx.pageSpace}`}>
                                    <TenderTable
                                      pathname={pathname}
                                      cx={tendercx}
                                      tenderList={tenderList}
                                      getTenderList={getProfile}
                                    />
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>
                    </div>
                    {/* <Row>
                      {productList
                        ?.filter((item: any) => item)
                        ?.filter((item: any) => !item.sold)
                        ?.map((product: any, index: number) => (
                          <Col lg={6} md={12} className="m-auto" key={index}>
                            <OffersCard
                              productData={product}
                              getProductList={getProductList}
                              markAsSold={markAsSold}
                              handleDelete={handleOpenModal}
                            />
                          </Col>
                        ))}
                    </Row> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="soldProduct">
                    <Row>
                      {productList
                        ?.filter((item: any) => item)
                        ?.filter((item: any) => item.sold)
                        ?.map((product: any, index: number) => (
                          <Col lg={6} md={12} key={index}>
                            <SoldProductCard
                              productData={product}
                              getProductList={getProductList}
                            />
                          </Col>
                        ))}
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>

      {selectedId !== null && (
        <DeletePopupModal
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onDeleteSuccess={handleDeleteSuccess}
          getProductList={getProductList}
          id={selectedId}
          apiUrl={`${process.env.REACT_APP_BASEURL}/product/product_form`} // Replace with your API endpoint
          title="Delete Item"
          message="Are you sure you want to delete this item permanently?"
        />
      )}
    </>
  );
};

export default MyOffers;
