import React, { useEffect } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import { MDBCheckbox } from "mdb-react-ui-kit";

const SignupSeller = (props: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <div className={`${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={5} md={6} sm={12} className={`m-auto`}>
              <div className={`${cx.onboardingBox}`}>
                <div className={`mb-4 ${cx.onboardingHeading}`}>
                  <h1>Create An Account</h1>
                  <p>Welcome Back! Slect Methode to Register</p>
                </div>

                <div className={`${cx.formStart}`}>
                  <div className={`mb-3 position-relative`}>
                    <CiMail />
                    <input
                      className={`form-control`}
                      type="type"
                      placeholder="Full Address"
                    />
                  </div>
                  <div className={`mb-3 position-relative`}>
                    <CiMail />
                    <input
                      className={`form-control`}
                      type="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className={`mb-3 position-relative`}>
                    <CiLock />
                    <input
                      className={`form-control`}
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className={`mb-3 position-relative`}>
                    <CiLock />
                    <input
                      className={`form-control`}
                      type="password"
                      placeholder="Confirm Password"
                    />
                  </div>
                  <div className={`mb-3 d-flex gap-3 ${cx.checkBox}`}>
                    <MDBCheckbox
                      name="flexCheck"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label>
                      I agree to<NavLink to="#"> Terms & Conditions </NavLink>
                    </label>
                  </div>

                  <div className={`${cx.onboardingButton}`}>
                    <NavLink to="/otp" className={`btn`}>
                      Sign Up
                    </NavLink>
                  </div>

                  <p>
                    Have an account already?{" "}
                    <NavLink to="/login">Login Your Account</NavLink>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignupSeller;
