export function removeDuplicatesCaseInsensitive(arr) {
  const lowerCaseSet = new Set();

  return arr.filter((str) => {
    const lowerCaseStr = str.toLowerCase();
    if (lowerCaseSet.has(lowerCaseStr)) {
      return false;
    } else {
      lowerCaseSet.add(lowerCaseStr);
      return true;
    }
  });
}
