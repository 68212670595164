// reducers.ts
import { combineReducers } from "@reduxjs/toolkit";
import dataReducer from "./dataReducer";
import registrationReducer from "./registrationReducer";

const rootReducer = combineReducers({
  register: registrationReducer,
  data: dataReducer,
});

export default rootReducer;
