import React from "react";
import cx from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";

const TermAndConditions = (props: any) => {
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    // autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>

      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`} style={{ transform: 'translate(0%, -160%)' }}>
                <h1 style={{ maxWidth: '1320px', marginLeft: 'auto', marginRight: 'auto', padding: '0px 6px' }}>Conditions Générales de Publication et d'Utilisation pour CongoClick.com</h1>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.contentPage} pt-4`}>
        <Container>
          <Row>
            <Col md="10" sm="12">
              <div className={`${cx.content}`}>
                <h2>1. Conditions Générales de Publication</h2>
                <p className="mb-1">
                  Les présentes Conditions Générales de Publication s'appliquent à tous les utilisateurs souhaitant soumettre du contenu sur le site CongoClick.com.
                </p>
                <p>
                  Elles définissent les règles et responsabilités liées à la publication de contenus en ligne.
                </p>

                <h3>1.1. Propriété Intellectuelle</h3>
                <p className="mb-1">
                  Les auteurs conservent les droits d'auteur sur leurs contenus soumis sur CongoClick.com.
                  Toutefois, en soumettant du contenu, les utilisateurs accordent à CongoClick.com un droit non exclusif, transférable, sous-licenciable, et mondial pour publier, reproduire, et diffuser le contenu.
                </p>
                <p>
                  Les utilisateurs garantissent qu'ils disposent de tous les droits nécessaires pour publier le contenu.
                </p>

                <h3>1.2. Contenus Interdits</h3>
                <p className="mb-1">
                  Les utilisateurs s'engagent à ne pas soumettre de contenu qui:
                </p>
                <ul>
                  <li>Enfreint les lois en vigueur en République Démocratique du Congo.</li>
                  <li>Contient des propos haineux, diffamatoires, ou discriminatoires.</li>
                  <li>Est à caractère pornographique, violent ou incitant à la violence.</li>
                  <li>Violerait les droits d'autrui, y compris les droits de propriété intellectuelle.</li>
                </ul>

                <h3>1.3. Responsabilité de l'Utilisateur</h3>
                <p className="mb-1">Les utilisateurs sont entièrement responsables des contenus qu'ils soumettent.</p>
                <p>CongoClick.com se réserve le droit de refuser ou de supprimer tout contenu ne respectant pas les présentes Conditions Générales de Publication.</p>

                <h2>2. Conditions Générales d'Utilisation</h2>
                <p className="mb-1">Les présentes Conditions Générales d'Utilisation encadrent l'accès et l'utilisation des services proposés par CongoClick.com.</p>
                <p>En accédant au site, les utilisateurs acceptent les termes suivants:</p>

                <h3>2.1. Accès aux Services</h3>
                <p className="mb-1">L'accès à CongoClick.com est gratuit. Toutefois, certains services ou contenus peuvent nécessiter une inscription ou un abonnement payant.</p>
                <p>L'utilisateur s'engage à fournir des informations exactes lors de son inscription.</p>

                <h3>2.2. Obligations des Utilisateurs</h3>
                <p className="mb-1">Les utilisateurs s'engagent à:</p>

                <ul>
                  <li>Utiliser le site conformément aux lois en vigueur en RDC.</li>
                  <li>Ne pas tenter de contourner les mesures de sécurité du site.</li>
                  <li>Respecter les autres utilisateurs et les règles de conduite établies.</li>
                </ul>

                <h3>2.3. Limitation de Responsabilité</h3>
                <p className="mb-1">CongoClick.com ne peut être tenu responsable des contenus publiés par les utilisateurs.</p>
                <p>Le site ne garantit pas un fonctionnement sans interruption ou sans erreurs et décline toute responsabilité en cas de pertes ou dommages indirects liés à l'utilisation du site.
                </p>

                <h2>3. Politique de Confidentialité</h2>
                <p>La présente Politique de Confidentialité explique comment CongoClick.com collecte, utilise, et protège les données personnelles de ses utilisateurs, conformément à la Loi n° 18/007 du 10 juin 2018 relative à la protection des données à caractère personnel en République Démocratique du Congo.</p>

                <h3>3.1. Données Collectées</h3>
                <p>CongoClick.com collecte les données suivantes:</p>
                <ul>
                  <li>Informations fournies lors de l'inscription (nom, email, numéro de téléphone).</li>
                  <li>Données de navigation (adresse IP, pages visitées, temps passé sur le site).
                  </li>
                </ul>

                <h3>3.2. Utilisation des Données</h3>
                <p>Les données collectées sont utilisées pour :</p>
                <ul>
                  <li>Analyses Statistiques</li>
                  <li>Fournir, améliorer les services et respecter les obligations légales.</li>
                  <li>Personnaliser l'expérience utilisateur.</li>
                </ul>

                <h3>3.3. Partage des Données</h3>
                <p>CongoClick.com ne partage pas les données personnelles avec des tiers, sauf si cela est nécessaire pour respecter une obligation légale ou pour fournir un service demandé par l'utilisateur.</p>

                <h3>3.4. Sécurité des Données</h3>
                <p>CongoClick.com met en œuvre des mesures techniques et organisationnelles appropriées pour protéger les données personnelles contre tout accès non autorisé, perte, ou altération.</p>

                <h3>3.5. Droits des Utilisateurs</h3>
                <p>Accès à leurs données personnelles.</p>
                <ul>
                  <li>Rectification ou suppression de leurs données.</li>
                  <li>Opposition au traitement de leurs données pour des motifs légitimes.</li>
                </ul>
                <p>
                  Pour exercer ces droits, les utilisateurs peuvent contacter CongoClick.com à l'adresse email :Congoclickwebsite@gmail.com.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermAndConditions;
