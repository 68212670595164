import React from "react";

interface Props {
  createdAt: string;
}

const PastTimeMention: React.FC<Props> = ({ createdAt }) => {
  const calculateTimeDifference = () => {
    const createdAtDate = new Date(createdAt);
    const currentTime = new Date();
    const differenceInSeconds = Math.floor(
      (currentTime.getTime() - createdAtDate.getTime()) / 1000
    );

    const seconds = differenceInSeconds;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);

    if (months > 0) {
      return `${months} month${months === 1 ? "" : "s"} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks === 1 ? "" : "s"} ago`;
    } else if (days > 0) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    }
  };

  return <span>{calculateTimeDifference()}</span>;
};

export default PastTimeMention;
