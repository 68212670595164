import React, { Fragment, useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { HiPlusSmall } from "react-icons/hi2";
import { FaPlus } from "react-icons/fa";

const PostAddFormEdit = () => {
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { showToast, updateToast } = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    trigger,
    control,
    setValue,
  }: any = useForm({});

  const [dynamicForm, setDynamicForm] = useState([]);
  const [subCategory, setSubcategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageInPopup, setImageInPopup] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [product, setProduct] = useState<any>({});

  const [isSubmit, setIsSubmit] = useState({ draft: false, submit: false });

  const description = watch("description", "");

  // Update character count whenever description changes
  React.useEffect(() => {
    setCharCount(description.length > 800 ? 800 : description.length);
  }, [description]);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    if (value.length <= 800) {
      setValue("description", value);
      setCharCount(value.length);
      trigger("description"); // Trigger validation on change
    } else {
      // If the length exceeds 800, truncate the value
      event.target.value = value.substring(0, 800);
    }
  };

  async function getDynamicFormByCatAndSubCat({
    category_id,
    sub_category_id,
    id = null,
    product_form_data,
  }) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/dynamic-form/?category_id=${category_id}&sub_category_id=${sub_category_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        showToast({
          type: "info",
          message: data.message || "Something went wrong!",
        });
        throw new Error(data.message || response.status);
      }

      // Handle response if necessary
      const dynamicData = await response.json();

      const data = dynamicData;
      if (data && data[0]?.sub_category_form_status === true) {
        if (!id) {
          setDynamicForm(data?.[0]?.sub_category_form);
        } else {
          const editForm = data?.[0]?.sub_category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      } else if (
        data &&
        data[0]?.sub_category_form_status === false &&
        data[0]?.category_form_status === true
      ) {
        if (!id) {
          setDynamicForm(data?.[0]?.category_form);
        } else {
          const editForm = data?.[0]?.category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      } else if (
        data &&
        data[0]?.sub_category_form_status === true &&
        data[0]?.category_form_status === true
      ) {
        if (!id) {
          setDynamicForm(data?.[0]?.sub_category_form);
        } else {
          const editForm = data?.[0]?.sub_category_form.map((item) => {
            if (item.fieldHeading in product_form_data) {
              item.fieldValue = product_form_data[item.fieldHeading];
              return item;
            }
            return item;
          });
          setDynamicForm(editForm);
        }
      }

      return data;
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getProductDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${params?.id!}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setProduct(data);
      console.log(data, "getProductDetails()");
      setImages(data?.product_images);

      reset({
        id: data?.id,
        title: data?.title,
        description: data?.description,
        price: data?.price,
        municipality: data?.municipality,
        location: data?.location,
      });
      getDynamicFormByCatAndSubCat({
        category_id: data?.category?.id,
        sub_category_id: data?.sub_category?.id,
        id: data?.id,
        product_form_data: data?.product_form_data,
      });
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const [images, setImages] = useState([]);

  useEffect(() => {
    getProductDetails();
  }, []);

  const onSubmit = async ({
    title,
    category,
    sub_category,
    images,
    price,
    payment_status,
    municipality,
    location,
    description,
    id,
    product_form_data,
    ...rest
  }) => {
    setIsSubmit((prev) => {
      if (payment_status === "draft") {
        return { ...prev, draft: true };
      } else {
        return { ...prev, submit: true };
      }
    });
    try {
      let user_id = JSON.parse(localStorage.getItem("userData"));
      const formData = new FormData();
      formData.append("title", title);
      formData.append("category", product?.category?.id);
      formData.append("sub_category", product?.sub_category?.id);
      formData.append(
        "deleted_images",
        deleteImages
          .filter((item) => item && item?.id)
          .filter(function (item, pos, self) {
            return self.indexOf(item) === pos;
          })
          .map((item) => item?.id)
          .join(",")
      );
      Array.from(imageFiles).forEach((image) => {
        formData.append("uploaded_images", image);
      });
      formData.append("price", price ? price : product?.price);
      formData.append("location", location ? location : product?.location);
      formData.append(
        "municipality",
        municipality ? municipality : product?.municipality
      );
      formData.append("description", description);
      formData.append("product_form_data", JSON.stringify(rest));
      formData.append("user", user_id?.id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${product?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        if (data?.msg) {
          showToast({ type: "error", message: data?.msg });
        }
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();
      if (response.status === 201) {
        reset();
        showToast({ type: "success", message: "Product created successfully" });
        setTimeout(() => {
          navigate("/product-list");
        }, 500);
      }
      if (response.status === 200) {
        const data = await response.json();
        if (
          data?.msg === "Subscription plan expired please upgrade your plan"
        ) {
          showToast({ type: "error", message: data?.msg });
          return;
        }

        reset();
        showToast({ type: "success", message: "Product updated successfully" });
        setTimeout(() => {
          navigate("/offers");
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      // toast.error("Something went Wrong");
      showToast({ type: "error", message: "Something went Wrong" });
    } finally {
      setIsSubmit({ draft: false, submit: false });
    }
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length + images.length > 5) {
      showToast({ type: "error", message: "You can only upload 5 images" });
      return;
    }
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...selectedFiles]);

    const newImages = selectedFiles.map((file: any, index) => {
      return { image_url: URL.createObjectURL(file!) };
    });

    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    setDeleteImages((prevImgObj) => [
      ...prevImgObj,
      ...images.filter((_, i) => i === index),
    ]);
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  function onhide() {
    setIsImageModal(false);
  }

  useEffect(() => {
    if (params && params.id) {
      console.log(params, "dkjlkajdjksjldjajsdkjl;ajdjk");
      dynamicForm.forEach((form) => {
        if (form?.fieldType?.value === "dropdown") {
          setTimeout(() => {
            reset({
              [`${form?.fieldHeading}`]: form?.fieldValue,
            });
          }, 1000);
        }
      });
    }
  }, [dynamicForm, reset, params]);

  console.log(dynamicForm, "dynamicFormdynamicFormdynamicFormdynamicForm");

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Edit Product</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Col md={9} className="m-auto">
            <div className={`${cx.product_mainbox}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={6} className={`${st.formBox} mb-3`}>
                    <label className={`${st.labelTitle}`}>
                      Category:{" "}
                      <span style={{ color: "#0d6efd" }}>
                        {product?.category?.category_name}
                      </span>
                    </label>
                    {/* <Select /> */}
                  </Col>
                  <Col md={6} className={`${st.formBox} mb-3`}>
                    <label className={`${st.labelTitle}`}>
                      {" "}
                      Sub-category:{" "}
                      <span style={{ color: "#0d6efd" }}>
                        {product?.sub_category?.subcategory_name}
                      </span>
                    </label>
                    {/* <Select /> */}
                  </Col>
                  <Col md={6} className={`${st.formBox}`}>
                    <label className={`${st.labelTitle}`}> Product Title</label>
                    <input
                      type="text"
                      {...register("title", { required: true })}
                      className="form-control"
                      placeholder="Product-title"
                    />
                    {errors.title && (
                      <span className="text-danger">
                        This field is required
                      </span>
                    )}
                  </Col>
                  {dynamicForm?.map((form, index) => {
                    if (form?.fieldType?.value === "input") {
                      return (
                        <Col md={6} className={`${st.formBox}`} key={index}>
                          <label className={`${st.labelTitle}`}>
                            {form?.fieldHeading}
                          </label>
                          <input
                            type="text"
                            className={`form-control`}
                            {...register(`${form?.fieldHeading}`, {
                              required: form?.fieldRequired,
                              value: form?.fieldValue,
                            })}
                          />
                          {errors[`${form?.fieldHeading}`] && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </Col>
                      );
                    }
                    if (form?.fieldType?.value === "textarea") {
                      return (
                        <Col md={6} className={`${st.formBox}`} key={index}>
                          <label className={`${st.labelTitle}`}>
                            {" "}
                            {form?.fieldHeading}
                          </label>
                          <textarea
                            rows={4}
                            className={`form-control`}
                            {...register(`${form?.fieldHeading}`, {
                              value: form?.fieldValue,
                              required: form?.fieldRequired,
                            })}
                          />
                          {errors[`${form?.fieldHeading}`] && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </Col>
                      );
                    }
                    if (form?.fieldType?.value === "dropdown") {
                      // reset({ [form?.fieldHeading]: form?.fieldValue });
                      return (
                        <Col md={6} className={`${st.formBox}`} key={index}>
                          {/* <Col md={12} className={`${st.formBox}`}> */}
                          <label className={`${st.labelTitle}`}>
                            {form?.fieldHeading}
                          </label>
                          <Controller
                            name={`${form?.fieldHeading}`}
                            control={control}
                            rules={{
                              required: form?.fieldRequired,
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                isSearchable={false}
                                // value={form?.fieldValue}
                                className="react-dropdown"
                                classNamePrefix="dropdown"
                                options={form?.nestedOptions}
                                onChange={(event) => {
                                  field.onChange(event);
                                }}
                              />
                            )}
                          />
                          {errors[`${form?.fieldHeading}`] && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                          {/* </Col> */}
                        </Col>
                      );
                    }

                    if (form?.fieldType?.value === "date") {
                      return (
                        <Col md={6} className={`${st.formBox}`} key={index}>
                          <label className={`${st.labelTitle}`}>
                            {form?.fieldHeading}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            {...register(`${form?.fieldHeading}`, {
                              value: form?.fieldValue,
                              required: form?.fieldRequired,
                            })}
                          />
                          {errors[`${form?.fieldHeading}`] && (
                            <span className="text-danger">
                              this field is required
                            </span>
                          )}
                        </Col>
                      );
                    }

                    if (form?.fieldType?.value === "time") {
                      return (
                        <Col md={6} className={`${st.formBox}`} key={index}>
                          <label className={`${st.labelTitle}`}>
                            {form?.fieldHeading}
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            {...register(`${form?.fieldHeading}`, {
                              value: form?.fieldValue,
                              required: form?.fieldRequired,
                            })}
                          />
                          {errors[`${form?.fieldHeading}`] && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </Col>
                      );
                    }
                    if (form?.fieldType?.value === "radio") {
                      return (
                        <Col md={4} className={`${st.formBox}`} key={index}>
                          <label className={`${st.labelTitle}`}>
                            {form?.fieldHeading}
                          </label>
                          <div className="">
                            {form?.nestedOptions?.map((r) => {
                              return (
                                <>
                                  <div className="d-flex gap-1 mb-2">
                                    <input
                                      {...register(`${form?.fieldHeading}`, {
                                        required: form?.fieldRequired,
                                      })}
                                      id={`${r.value}`}
                                      type="radio"
                                      value={`${r.value}`}
                                      defaultChecked={
                                        form?.fieldValue === r.value
                                      }
                                    />
                                    <label
                                      className={`form-control ${st.labelTitle} mb-0`}
                                      htmlFor={`${r.value}`}
                                    >
                                      {r.value}
                                    </label>
                                  </div>
                                </>
                              );
                            })}
                            {errors[`${form?.fieldHeading}`] && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </div>
                        </Col>
                      );
                    }
                    return <Fragment key={index}></Fragment>;
                  })}
                  <Col md={6} className="">
                    <label className="labelTitle">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      {...register("price", {
                        required: "Price is required",
                        min: {
                          value: 0,
                          message: "Price cannot be negative",
                        },
                        validate: {
                          maxLength: (value) =>
                            value.length <= 15 ||
                            "Price cannot exceed 15 digits",
                        },
                        pattern: {
                          value: /^\d+$/,
                          message: "Only numerical values are allowed",
                        },
                      })}
                    />
                    {errors.price && (
                      <span className="text-danger">
                        {errors.price.message}
                      </span>
                    )}
                  </Col>

                  <Col md={6} className="">
                    <label className="labelTitle">location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Location"
                      {...register("location", {
                        required: "location is required",
                      })}
                    />
                    {errors.location && (
                      <span className="text-danger">
                        {errors.location.message}
                      </span>
                    )}
                  </Col>
                  <Col md={6} className="">
                    <label className="labelTitle">Municipality</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Municipality"
                      {...register("municipality", {
                        required: "Municipality is required",
                      })}
                    />
                    {errors.municipality && (
                      <span className="text-danger">
                        {errors.municipality.message}
                      </span>
                    )}
                  </Col>

                  <Col md={12} className={` ${st.formBox} mt-3`}>
                    <label className={`${st.labelTitle}`}>Description</label>
                    <textarea
                      className="form-control h-auto"
                      rows={4}
                      placeholder="Type here..."
                      {...register("description", {
                        required: "Description is required",
                        maxLength: {
                          value: 800,
                          message: "Description cannot exceed 800 characters",
                        },
                        onChange: (e) => {
                          handleDescriptionChange(e);
                        },
                      })}
                    ></textarea>
                    <small className="form-text text-muted">
                      {charCount}/800 characters
                    </small>
                    {errors.description && (
                      <p className="text-danger">
                        {errors.description.message}
                      </p>
                    )}
                  </Col>
                  <Col md={12} className={` ${st.formBox}`}>
                    <label htmlFor="taxtno" className={`${st.labelTitle}`}>
                      Product Images
                    </label>

                    <div className={`${st.file_inputContainer}`}>
                      {images.map((image, index) => (
                        <div
                          key={index}
                          className={`${st.imgs_preview} cursor-pointer`}
                        >
                          <img
                            src={image?.image_url}
                            alt={`uploaded ${index}`}
                            className={`${st.imgshow}`}
                            onClick={() => {
                              setIsImageModal(true);
                              setImageInPopup(image?.image_url);
                            }}
                          />
                          <div
                            className={`${st.removeButton}`}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <RxCross2 />
                          </div>
                        </div>
                      ))}
                      <label className={`${st.custom_fileInput}`}>
                        {/* <HiPlusSmall /> */}
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          style={{ display: "none" }}
                          multiple
                          {...register("images", {
                            required: images.length > 0 ? false : true,
                            onChange: (e) => handleImageChange(e),
                          })}
                        />
                        <span className={`${st.plusIcon}`}>
                          <FaPlus />
                        </span>
                      </label>
                    </div>
                    {errors.images && (
                      <span className="text-danger">
                        Product Image is required
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={`${cx.nextBack_btn}`}>
                      <div></div>
                      <button className="btn">Submit</button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Container>
      </section>
    </>
  );
};

export default PostAddFormEdit;
