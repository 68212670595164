import axios, { AxiosResponse } from "axios";

const BASE_URL = process.env.REACT_APP_BASEURL; // Replace this with your actual API base URL

const api = axios.create({
  baseURL: BASE_URL,
});

export const get = async <T>(
  endpoint: string,
  headers?: Record<string, string>
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.get(endpoint, { headers });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const post = async <T>(
  endpoint: string,
  data: any,
  headers?: Record<string, string>
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.post(endpoint, data, {
      headers,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const put = async <T>(
  endpoint: string,
  data: any,
  headers?: Record<string, string>
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.put(endpoint, data, {
      headers,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const patch = async <T>(
  endpoint: string,
  data: any,
  headers?: Record<string, string>
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.patch(endpoint, data, {
      headers,
    });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const remove = async <T>(
  endpoint: string,
  headers?: Record<string, string>
): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await api.delete(endpoint, { headers });
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
