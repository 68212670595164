import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Container, Col } from "react-bootstrap";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";

const NewsPostForm = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];

  async function getNewsArticles() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/articlecategory/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      const news_articles = data.map((item: { id: number; name: string }) => ({
        value: item?.id,
        label: item?.name,
      }));
      setNewsArticles(news_articles);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const onSubmit = async ({
    title,
    news_article,
    description,
    news_image,
  }: any) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("news_article", news_article.value);
      formData.append("description", description);
      formData.append("news_image", news_image[0]);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/news/news/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        showToast({
          type: "success",
          message: "News Post Created Successfully",
        });
      }
      reset();
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error);
    }
  };

  useEffect(() => {
    getNewsArticles();
    window.scrollTo(0, 0);
  }, []);

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>Post A News</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.productForm_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col md={9} className="m-auto">
              <div className={`${cx.product_mainbox}`}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.form_heading}`}>
                        <h3>News Details</h3>
                      </div>
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>News Title:</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("title", { required: true })}
                      />
                      {errors.title && <span>This field is required</span>}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        News Article:
                      </label>
                      <Controller
                        name="news_article"
                        control={control}
                        rules={{
                          required: "This field is required",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isSearchable={false}
                            className="react-dropdown"
                            classNamePrefix="dropdown"
                            options={newsArticles}
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                          />
                        )}
                      />
                      {Object.keys(errors).includes("news_article") && (
                        <p style={{ color: "red" }}>This Field is Required</p>
                      )}
                      {/* <select
                        className="form-control form-select"
                        aria-label="Default select example"
                      >
                        <option selected disabled>
                          Category of the News Article:
                        </option>
                        <option value="Politics">Politics</option>
                        <option value="Society">Society</option>
                      </select> */}
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <input
                        type="file"
                        className="form-control"
                        {...register("news_image", { required: true })}
                      />
                      {errors.news_image && <span>This field is required</span>}
                    </Col>
                    <Col md={12} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Description:</label>
                      <Controller
                        name="description"
                        control={control}
                        rules={{
                          required: "Please enter task description",
                        }}
                        render={({ field }) => (
                          <ReactQuill
                            {...field}
                            placeholder={"Write Description"}
                            modules={modules}
                            formats={formats}
                            onChange={(text) => {
                              field.onChange(text);
                            }}
                          />
                        )}
                      />
                      {/* <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={code}
                        onChange={handleProcedureContentChange}
                      /> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className={`${cx.nextBack_btn}`}>
                        <button className="btn" type="submit">
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsPostForm;
