import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "./index.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import { FaStar } from "react-icons/fa";
import { Checkbox } from "../Forms";
import st from "../../../assets/stylesheet/style.module.scss";
import { Slider, Switch } from "@mui/material";
import { Form } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import { Controller } from "react-hook-form";
import { removeDuplicatesCaseInsensitive } from "../../../utils/removeduplicate";
import Select, { InputActionMeta } from "react-select";
import CheckboxOption from "./Checkbox";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { global_search } from "../../../redux_toolkit/reducer/dataReducer";

// import { IoClose } from "react-icons/io5";
// import { IoIosCloseCircle, IoIosCloseCircleOutline } from "react-icons/io";

function valuetext(value: number) {
  return `${value}°C`;
}
const ratings = [5, 4, 3, 2, 1];

interface Option {
  label: string;
  value: string;
}

export default function FilterSidebar({
  register,
  onSubmit,
  control,
  setValue: updateValue,
  watch,
  getValues,
  dispatch,
  storedSearch,
  categoryList,
  subCategoryList,
  getSubCategoryList,
}: any) {
  const [locations, setLocations] = useState([]);
  const [municipality, setMunicipality] = useState([]);
  const [price, setPrice] = useState([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const [value, setValue] = React.useState<number[]>([20, 80]);
  const [_, setScroll] = useState(false);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    getFilterList();
  }, []);

  const handleInputChange = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      setInputValue(newValue);
      setMenuIsOpen(newValue.length > 0); // Open the menu only if there's input
    },
    []
  );

  const [searchInput, setSearchInput] = useState("");

  const filterCities = () => {
    const filteredCities = locations
      .filter((item: any, index: number) => locations.indexOf(item) === index)
      .filter((city: any) =>
        city.toLowerCase().includes(searchInput.toLowerCase())
      );
    return filteredCities;
  };

  async function getFilterList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/list-of-product-fields/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      const price = data.price
        .filter((item: any) => item)
        .map((item: any) => Math.ceil(item));

      setLocations(data.locations.filter((item: any) => item));
      setMunicipality(data.municipality.filter((item: any) => item));
      setPrice(price);
      setValue([
        price && price?.length ? Math.min(...price) : 20,
        price && price?.length ? Math.max(...price) : 80,
      ]);

      if (storedSearch?.storedSearchStatus) {
        setTimeout(() => {
          setSelectedOptions(watch("municipality") ?? []);
        }, 2000);
      }

      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (option: Option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.some((item) => item.value === option.value)
        ? prevSelected.filter((item) => item.value !== option.value)
        : [...prevSelected, option]
    );
  };

  const handleRemove = (option: Option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.filter((item) => item.value !== option.value)
    );
  };
  const filteredOptions = useMemo(() => {
    return removeDuplicatesCaseInsensitive(municipality)?.map((item) => ({
      label: item,
      value: item,
    }));
  }, [municipality]);
  // .filter(
  //   (option) => !selectedOptions.some((item) => item.value === option.value)
  // )

  const handleClearAll = () => {
    setSelectedOptions([]);
    updateValue("municipality", []);
    onSubmit();
  };

  useEffect(() => {
    updateValue("municipality", selectedOptions);
    onSubmit();
  }, [selectedOptions]);

  const CustomClearIndicator = () => null;
  const watchingCategory = watch("category");
  const salesAndRentFilter =
    watchingCategory &&
    (watchingCategory?.label.toLowerCase() === "automobile" ||
      watchingCategory?.label?.toLowerCase() === "immobilier");
  const [checked, setChecked] = React.useState(false);
  const handleIntendedToChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChecked(event?.target?.checked);
    dispatch(global_search(event?.target?.checked ? "rent" : "sale"));
  };
  useEffect(() => {
    // const timer = setTimeout(() => {
    if (salesAndRentFilter) {
      onSubmit();
    }
    // }, 1000);

    // Clean up the timeout if the component unmounts or before running the effect again
    // return () => clearTimeout(timer);
  }, [checked, watchingCategory]); // Depend on 'checked' state

  return (
    <>
      <div>
        <div className={`${cx.filterSidebar}`}>
          <div className={`${cx.select_dropdown_box}`}>
            <label>Categories</label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isSearchable={true}
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={categoryList}
                  onChange={(event) => {
                    field.onChange(event);
                    if (salesAndRentFilter) {
                      dispatch(global_search(checked ? "rent" : "sale"));
                    } else {
                      dispatch(global_search(""));
                    }
                    onSubmit(event);
                    getSubCategoryList({ category_id: event?.value });
                  }}
                />
              )}
            />
          </div>
          <div className={`${cx.select_dropdown_box} mt-3`}>
            <label>Sub-categories</label>
            <Controller
              name="sub_category"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isSearchable={true}
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={subCategoryList}
                  onChange={(event) => {
                    field.onChange(event);
                    onSubmit(event);
                  }}
                />
              )}
            />
          </div>
          {salesAndRentFilter ? (
            <div className={`${cx.select_dropdown_box} mt-3`}>
              <label>Sale/Rent</label>
              <div className={`${cx.checkBox_customize}`}>
                <Form className="forMobile">
                  <label htmlFor="">For Sale</label>
                  {/* <Form.Check type="switch" id="custom-switch" /> */}
                  <Switch
                    checked={checked}
                    onChange={handleIntendedToChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <label htmlFor="">For Rent</label>
                </Form>
              </div>
            </div>
          ) : null}

          <div className={`${cx.cardBox} mt-3`}>
            <h5 className={`${cx.filterTitle}`}>Price Filter($)</h5>
            <div className={`${cx.rangeFilter}`}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    getAriaLabel={() => "Minimum distance"}
                    value={field.value ?? value}
                    min={0}
                    max={price && price?.length ? Math.max(...price) : 100}
                    onChange={(_: any, value) => {
                      field.onChange(value);
                      handleChange(_, value);
                      onSubmit(value);
                    }}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    disableSwap
                  />
                )}
              />
            </div>
          </div>

          <div className={`${cx.cardBox}`}>
            <h5 className={`${cx.filterTitle}`}>All Cities</h5>
            <div>
              <Form className="d-flex search_relatedBtn mb-3">
                <Form.Control
                  type="search"
                  placeholder="Search Cities..."
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Form>
            </div>
            <ul className={`${cx.listMenu} ${cx.scrollList}`}>
              {filterCities().map((city: string, index: number) => (
                <li key={index}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={city}
                      name="location"
                      // getValues={getValues}

                      onSubmit={onSubmit}
                    />
                    <div className={`${cx.name}`}>{city}</div>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className={`${cx.cardBox}`}>
            <h5 className={`${cx.filterTitle}`}>All Muncipalities</h5>
            <Controller
              name="municipality"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={filteredOptions}
                  isMulti
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  // inputValue={inputValue}
                  // onInputChange={handleInputChange}
                  // menuIsOpen={menuIsOpen} // Control the open state of the menu
                  components={{
                    Option: (props: any) => {
                      return (
                        <CheckboxOption
                          label={props.label}
                          value={props.value}
                          checked={
                            field.value?.some(
                              (item: Option) => item.value === props.value
                            ) || false
                          }
                          onChange={() => {
                            field.onChange(
                              field.value?.some(
                                (item: Option) => item.value === props.value
                              )
                                ? field.value.filter(
                                    (item: Option) => item.value !== props.value
                                  )
                                : [...(field.value || []), props.data]
                            );
                            handleCheckboxChange(props.data);
                          }}
                        />
                      );
                    },
                    ClearIndicator: CustomClearIndicator, // Hide the ClearIndicator
                  }}
                />
              )}
            />

            <div className={`${cx.municipality_listingShow}`}>
              <ul>
                {selectedOptions.map((option) => (
                  <li key={option.value}>
                    {option.label}{" "}
                    <button type="button" onClick={() => handleRemove(option)}>
                      <IoIosCloseCircleOutline />
                    </button>
                  </li>
                ))}
              </ul>
              {selectedOptions.length > 0 ? (
                <div className={`${cx.clearBtn}`}>
                  <button onClick={handleClearAll}>Clear All</button>
                </div>
              ) : null}
            </div>
            {/* {municipality && municipality?.length && (
            <ul className={`${cx.listMenu}`}>
              {removeDuplicatesCaseInsensitive(municipality)?.map(
                (item, index: number) => (
                  <li key={index}>
                    <label className={`${st.checkbox}`}>
                      <Checkbox
                        register={register}
                        value={item}
                        name="municipality"
                        // getValues={getValues}
                        onSubmit={onSubmit}
                      />{" "}
                      <div className={`${cx.name}`}>{item}</div>
                    </label>
                  </li>
                )
              )}
            </ul>
          )} */}
          </div>

          <div className={`${cx.cardBox}`}>
            <h5 className={`${cx.filterTitle}`}>Rating</h5>
            <ul className={`${cx.rating}`}>
              {ratings.map((rate: any, index: number) => (
                <li className={`${cx.active}`} key={index}>
                  <label className={`${st.checkbox}`}>
                    <Checkbox
                      register={register}
                      value={rate}
                      name="rating"
                      // getValues={getValues}
                      onSubmit={onSubmit}
                    />
                    <div className={`${cx.stars}`}>
                      <Rating
                        name="simple-controlled"
                        value={rate}
                        size="small"
                        precision={1}
                        disabled
                        max={rate}
                      />
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
