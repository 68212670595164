import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux_store/store";

const initialState = {
  token: "",
};

const registrationSlice: any = createSlice({
  name: "register",
  initialState,
  reducers: {
    logoutandChangeHeader(state = initialState) {
      state.token = "";
    },
    loginandChangeHeader(state = initialState) {
      state.token = localStorage.getItem("token")!;
    },
  },
});

export const { logoutandChangeHeader, loginandChangeHeader } =
  registrationSlice.actions;

export const getToken = (state: RootState) => state.register;
export default registrationSlice.reducer;
