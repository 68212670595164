import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import { Col, Container, Row, Table } from "react-bootstrap";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { profile } from "../../../assets/images";
import SellerDetails from "../../../components/Modals/SellerDetails";
import PastTimeMention from "../../../utils/PastTimeMention";
import Rating from "@mui/material/Rating";
import { useForm } from "react-hook-form";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { allBgBanner, mainBannerNew } from "../../../assets/images";
import useToast from "../../../utils/useToast";

const ProductDetails = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { showToast } = useToast();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showSellerDetails, setSellerDetailsShow] = useState(false);
  const handleSellerDetailsClose = () => setSellerDetailsShow(false);

  const [showAddPostCard, setAddPostCardShow] = useState(false);
  const handleAddPostCardClose = () => setAddPostCardShow(false);

  const [value, setValue] = React.useState<number | null>(2);
  const [productData, setProductData] = useState<any>({});

  async function getProductList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${params?.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      data.product_form_data.price = data.price;
      data.product_form_data.municipality = data.municipality;
      data.product_form_data.location = data.location;
      setProductData(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getProductList();
  }, []);

  const { register, handleSubmit, reset } = useForm();

  const onSubmitRatingReview = async ({ review }: any) => {
    if (!JSON?.parse(localStorage?.getItem("userData")!)?.id) {
      showToast({ type: "error", message: "Please Login" });
      return;
    }

    if (
      productData?.review?.some(
        (item) =>
          item?.user?.id === JSON?.parse(localStorage?.getItem("userData")!)?.id
      )
    ) {
      showToast({ type: "info", message: "Review already submitted" });

      return;
    }
    try {
      const formData = JSON.stringify({
        user: JSON.parse(localStorage.getItem("userData")!).id,
        product: productData?.id,
        review: review,
        rating: value,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/review/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      if (response.status === 201 && response.statusText === "Created") {
        showToast({
          type: "success",
          message: "Rating and  Review Submitted Successfully!",
        });

        getProductList();
      }
      reset();
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  async function markAsSold(id) {
    try {
      const formData = JSON.stringify({
        sold: true,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setTimeout(() => {
        getProductList();
      }, 1000);
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function selectProductPlan() {
    try {
      const formData = JSON.stringify({
        plan: 1,
        plan_type: true,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/${params?.id}/
        `,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
            "Content-Type": "application/json",
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      if (response.ok) {
        const data = await response.json();
        // toast.success("Product Plan updated Successfully");
        setTimeout(() => {
          navigate("/", { replace: true });
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error);
    } finally {
      getProductList();
      handleAddPostCardClose();
    }
  }

  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  console.log(productData, "productData");

  return (
    <>
      <div className={`${st.header_height}`}></div>
      <section className={`${st.create_register}`}>
        <Container fluid className="p-0">
          <Row>
            <Col md={12}>
              <OwlCarousel className="owl-theme" {...options4}>
                <div className="item">
                  <div className="m-0">
                    <img src={allBgBanner} alt="aid" />
                  </div>
                </div>
                <div className="item">
                  <div className="m-0">
                    <img src={mainBannerNew} alt="aid" />
                  </div>
                </div>
              </OwlCarousel>

              <div className={`${st.main_heading} ${st.slider_content}`}>
                <h1>
                  {location?.pathname.includes("/product-details/")
                    ? "Product Detail"
                    : "Post Ad"}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className={`${cx.profileSection}`}>
        <Container>
          <Row>
            <Col
              xl={6}
              lg={7}
              md={9}
              sm={12}
              // style={{ margin: '0 auto' }}
            >
              <div className={`${cx.profileBox}`}>
                <div className={`${cx.left}`}>
                  <img src={profile} alt="user-review" />
                  <div className={`${cx.details}`}>
                    <h6>Posted by</h6>
                    <h5>
                      {productData?.user?.firstName}{" "}
                      {productData?.user?.lastName}
                    </h5>
                  </div>
                </div>
                <div className={`${cx.right}`}>
                  {!productData?.sold &&
                    productData?.user?.id ===
                      JSON?.parse(localStorage?.getItem("userData")!)?.id && (
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          markAsSold(productData?.id);
                        }}
                      >
                        Mark as Sold
                      </button>
                    )}
                </div>

                {/* {productData?.user?.id !==
                  JSON?.parse(localStorage?.getItem("userData")!)?.id && (
                    <div className={`${cx.right}`}>
                      <NavLink
                        to={"#"}
                        onClick={handleRatingReviewShow}
                        className="btn"
                      >
                        Give Rating & Review
                      </NavLink>
                    </div>
                  )} */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className={`${cx.categories_slider} ${st.spb} `}>
        <Container>
          <Row>
            <Col md={6}>
              <Carousel>
                {productData?.product_images?.map((item: any, index: any) => (
                  <div key={index} className={`${cx.sliderImage}`}>
                    {productData?.sold && (
                      <span className={`${cx.soldProductLabel}`}>Sold</span>
                    )}
                    <img src={item?.image_url} alt={`Slide ${index}`} />
                  </div>
                ))}
              </Carousel>
              <Col>
                <div className={`${cx.sellerdetailstext}`}>
                  <h4>Seller Details :</h4>
                  {productData?.user?.email ? (
                    <p>
                      <b> Email:</b>
                      <span>{productData?.user?.email}</span>
                    </p>
                  ) : (
                    <></>
                  )}
                  {productData?.user?.phoneNo ? (
                    <p>
                      <b>Phone No:</b>
                      <span>{productData?.user?.phoneNo}</span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                {productData?.user?.id !==
                  JSON?.parse(localStorage?.getItem("userData")!)?.id && (
                  <div
                    className={`${cx.sellerdetailstext} ${cx.sellerText_input}`}
                  >
                    <form onSubmit={handleSubmit(onSubmitRatingReview)}>
                      <h4>
                        Give Rating :
                        <span>
                          <Rating
                            name="simple-controlled"
                            value={value}
                            size="large"
                            precision={0.5}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                          />
                        </span>
                      </h4>
                      <div className={`${cx.typeText_box}`}>
                        <div className="form-group">
                          <textarea
                            {...register("review")}
                            className="form-control"
                            placeholder="Leave a message..."
                            name="review"
                          ></textarea>
                        </div>
                        <button type="submit">Submit</button>
                      </div>
                    </form>
                  </div>
                )}
              </Col>
            </Col>

            <Col md={6}>
              <Col xl={9} lg={11} col-md-12 className="m-auto">
                <div className={`${cx.table_specifyBox}`}>
                  <h3>Specification</h3>
                  <Table borderless>
                    {productData && Object?.keys(productData)?.length && (
                      <tbody>
                        {Object?.entries(productData?.product_form_data)?.map(
                          ([field, value]: any) => {
                            return (
                              <tr>
                                <td>{field}</td>
                                <td>
                                  {typeof value === "number" && (
                                    <b>
                                      {field === "price" ? "$ " : ""}
                                      {value}
                                    </b>
                                  )}
                                  {typeof value === "string" && <b>{value}</b>}
                                  {typeof value === "object" && (
                                    <b>{value?.value}</b>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                  </Table>

                  <div
                    className={`${cx.postBtn} flex-column gap-3 flex-sm-column flex-md-column flex-lg-row flex-xl-row`}
                  >
                    {/* {productData?.user?.id !==
                      JSON?.parse(localStorage?.getItem("userData")!)?.id &&
                      localStorage.getItem("token") && (
                        <NavLink
                          onClick={() => {
                            handleSellerDetailsShow();
                          }}
                          className="btn"
                          to={"#"}
                        >
                          Get Seller Details
                        </NavLink>
                      )} */}
                    {productData?.plan?.id &&
                      localStorage.getItem("token") &&
                      productData?.user?.id ===
                        JSON?.parse(localStorage?.getItem("userData")!)?.id &&
                      !productData?.plan_type && (
                        <NavLink
                          onClick={() => {
                            // handleAddPostCardShow();
                            selectProductPlan();
                          }}
                          className="btn"
                          to={"#"}
                        >
                          Post Ad
                        </NavLink>
                      )}
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${cx.reviewSection}`}>
        <Container>
          <Row>
            <Col md="12" sm="12">
              <div className={`${cx.productDescription}`}>
                <Tabs
                  defaultActiveKey="Description"
                  id="uncontrolled-tab-example"
                  className={`mb-4`}
                >
                  {/* <Tab eventKey="Item_Info" title="Additional Information">
                    <div className={`${cx.content}`}>
                      <h5>Know your vehicle’s year, make, and model</h5>
                      <p>
                        You begin with a text, you sculpt information, you
                        chisel away what's not needed, you come to the point,
                        make things clear, add value, you're a content person,
                        you like words. Design is no afterthought, far from it,
                        but it comes in a deserved second.
                      </p>
                      <Row>
                        <Col md={6}>
                          <Table className="table-striped table-bordered">
                            <tbody>
                              <tr>
                                <td>ABS</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>LED Daytime running lights</td>
                                <td>No</td>
                              </tr>
                              <tr>
                                <td>Full LED headlights</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Outside mirror electric</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Tinted rear windows</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Centre arm rest</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Driver drowsiness detection</td>
                                <td>No</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col md={6}>
                          <Table className="table-striped table-bordered">
                            <tbody>
                              <tr>
                                <td>Digital radio</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Isofixs</td>
                                <td>No</td>
                              </tr>
                              <tr>
                                <td>Onboard computer</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Central locking</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Driver drowsiness detection</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Keyless central locking</td>
                                <td>Yes</td>
                              </tr>
                              <tr>
                                <td>Lane departure warning system</td>
                                <td>No</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </Tab> */}
                  <Tab eventKey="Description" title="Overview">
                    <div
                      className={`${cx.content}`}
                      dangerouslySetInnerHTML={{
                        __html: productData?.description,
                      }}
                    ></div>
                  </Tab>
                  {location?.pathname !== "/post-ad" && (
                    <Tab
                      eventKey="Reviews"
                      title={`Reviews (${productData?.review?.length})`}
                    >
                      <div className={`${cx.comments_mainBox}`}>
                        {/* <div className={`${cx.heading_top}`}>Reviews</div> */}
                        {productData &&
                          productData?.review?.length > 0 &&
                          productData?.review?.map((review: any) => {
                            return (
                              <>
                                <div className={`${cx.commentsFlex}`}>
                                  <div className={`${cx.left_Imgbox}`}>
                                    <img
                                      src={
                                        review?.user?.sso_profile_image
                                          ? review?.user?.sso_profile_image
                                          : profile
                                      }
                                      alt="profile"
                                    />
                                  </div>
                                  <div>
                                    <div className={`${cx.right_detailBox}`}>
                                      <div className={`${cx.name_date}`}>
                                        <h6>
                                          {review?.user?.userName
                                            ? review?.user?.userName
                                            : `${review?.user?.firstName} ${review?.user?.lastName}`}
                                        </h6>
                                        <span>
                                          {" "}
                                          <PastTimeMention
                                            createdAt={
                                              "2024-04-22T14:11:16.879877Z"
                                            }
                                          />
                                        </span>
                                      </div>
                                      <p
                                        className={`${cx.content_text}`}
                                        dangerouslySetInnerHTML={{
                                          __html: review?.review,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                      </div>
                    </Tab>
                  )}
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <SellerDetails
        show={showSellerDetails}
        sellerDetails={productData?.user}
        handleSellerDetailsClose={handleSellerDetailsClose}
      />
      {/* <RatingReview
        show={showRatingReview}
        ratingNecessaryDetails={productData}
        getProductList={getProductList}
        handleRatingReviewClose={handleRatingReviewClose}
      /> */}

      {/* {showAddPostCard && (
        <AddPostCard
          show={showAddPostCard}
          productId={params?.id}
          planId={productData?.plan && productData?.plan?.id}
          getProductList={getProductList}
          handleAddPostCardClose={handleAddPostCardClose}
        />
      )} */}
    </>
  );
};

export default ProductDetails;
