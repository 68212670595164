import React from "react";
import m from "./Modal.module.scss";
import { Col, Modal, Row } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import JobFilter from "../../pages/Website/Job/JobFilter";

function JobToggle({
  show,
  handleJobToggleClose,
  getJobList,
  register,
  handleSubmit,
  reset,
  control,
  getValues,
  watch,
}) {
  return (
    <>
      <Modal
        centered
        show={show}
        scrollable
        onHide={handleJobToggleClose}
        aria-labelledby="example-modal-sizes-title-sm"
        className={m.modalCts}
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <button className={`${m.closeIcon}`} onClick={handleJobToggleClose}>
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div>
                <JobFilter
                  getJobList={getJobList}
                  register={register}
                  handleSubmit={handleSubmit}
                  reset={reset}
                  control={control}
                  getValues={getValues}
                  watch={watch}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JobToggle;
