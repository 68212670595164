import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";

function SuggestAdCarousel({ ads }) {
  return (
    <>
      <div className={`${st.suggest_AdsCarousel}`}>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            // disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          {ads?.map(({ id, title, url, image }) => {
            return (
              <SwiperSlide key={`bottom-ads` + id}>
                <NavLink to={url} target="_blank">
                  <div className="item">
                    <div className="m-0">
                      <img src={image} alt="aid" />
                    </div>
                  </div>
                </NavLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}

export default SuggestAdCarousel;
