import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useToast from "../../../utils/useToast";
import { MdDelete } from "react-icons/md";

const JobCard = ({ cx, jobList, getJobList ,pathname}: any) => {
  const { showToast } = useToast();

  const [favList, setFavList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("job", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/jobs/fav_job/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary

      if (response.status === 204 && response.statusText === "No Content") {
        showToast({ type: "info", message: "Job Removed from Favorite" });
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();
        showToast({ type: "success", message: data.message });
      }
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });

      console.error(error);
    } finally {
      getJobList();
      localStorage.getItem("userData")! && getProfile();
    }
  };
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      // tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_jobs } = await response.json();
      setFavList(favourite_jobs);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });

      console.error(error);
    }
  }
  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
  }, []);
  return (
    <>
      {jobList.map((job: any) => {
        return (
          <Col lg={4} md={6} key={job?.id}>
            <div className={`${cx.listingPostion}`}>
              <NavLink
                to={`/job-details/${job?.id}`}
                className={`${cx.listingCard}`}
              >
                <small>
                  {job.industry.name} | {job.subject_area.name}
                </small>
                {/* <FaRegStar
                          className={`${cx.starMark}`}
                          onClick={() => {
                            favorite(job.id);
                          }}
                        /> */}
                <h4>{job.job_title}</h4>
                <p dangerouslySetInnerHTML={{ __html: job.description! }}></p>
                <span
                  // to={`/job-details/${job?.id}`}
                  className={`${cx.readMore}`}
                >
                  Read more
                </span>
              </NavLink>
              <div className={`${cx.heart}  ${cx.starMark}`}>
              {pathname !== '/offers' && <button
                  className={`${favList?.some((item: any) => item.id === job.id)
                      ? cx.active
                      : ""  
                    }`}
                  onClick={() => {
                    if (!localStorage.getItem("userData")!) {
                      showToast({
                        type: "error",
                        message: "Something went wrong!",
                      });
                      navigate("/login");
                    } else {
                      // setFavorite(true);
                      favorite(job.id);
                    }
                  }}
                >
                  <FaHeart />
                </button>}  
            {pathname === '/offers' && <button className={`${cx.active}`}>
                              <MdDelete />
                            </button>}
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default JobCard;
