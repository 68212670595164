import React, { useCallback, useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  usaid,
  bagIcon,
  certificateIcon,
  communityIcon,
  whatsappIcon,
  telegramIcon,
  communityNew,
  mainBannerNew,
  mergeCommunity,
} from "../../../assets/images";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import ProductCard from "../../../components/Website/ProductCard";
import { HiOutlineHome, HiBuildingOffice2 } from "react-icons/hi2";
import { TbIdBadge } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa6";
import { ProductItem } from "../../../constants/Interface";
import SuggestAdCarousel from "../../../components/Website/SuggestAdCarousel";
import { MdOutlineHomeRepairService } from "react-icons/md";

const Home = (props: any) => {
  const navigate = useNavigate();
  const [productList, setProductList] = useState<ProductItem[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductList();
  }, []);

  const options2 = {
    loop: false,
    items: 4,
    dots: false,
    nav: true,
    navText: [
      `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`,
      `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`,
    ],
    autoplay: false,
    responsive: {
      0: {
        items: 4,
      },
      480: {
        items: 4,
      },
      768: {
        items: 4,
      },
      992: {
        items: 8,
      },
    },
  };

  const options3 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
    },
  };
  const options4 = {
    loop: true,
    items: 4,
    dots: true,
    nav: false,
    touchDrag: false,
    mouseDrag: false,
    // navText: [`<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>`, `<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg>`],
    autoplay: true,
    // autoplayTimeout:3000,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
    },
  };

  const [activeAdd, setActiveAdd] = useState(false);
  const [joinCommunityActive, setJoinCommunityActive] = useState(false);
  const [homepageCategory, setHomePageCategory] = useState<any[]>([

  ]);
  const [heroBannerAds, setHeroBannerAds] = useState<any[]>([]);
  const [bottomAds, setBottomAds] = useState<any[]>([]);

  async function getProductList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/product_form/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setProductList(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const displayMsg = (redirectURL: string) => {
    setTimeout(() => {
      if (localStorage.getItem("token")) {
        navigate(redirectURL);
      } else {
        navigate("/login");
      }
    }, 200);
  };

  useEffect(() => {
    getHeroBannerAdsList();
    getHomePageCategory();
    getBottomAdsList();
  }, []);

  async function getHeroBannerAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/hero-banner-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setHeroBannerAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  async function getBottomAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/bottom-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setBottomAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getHomePageCategory() {
    setHomePageCategory([])
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/website-category/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      const initialHomePageCategories = [{
        "id": 1,
        "category_name": "All Categories",
      },
      {
        "id": 9,
        "category_name": "Services",
      },
      {
        "id": 2,
        "category_name": "Tenders",
      }]

      const endHomePageCategories = [{
        "id": 6,
        "category_name": "Jobs",
      },
      {
        "id": 7,
        "category_name": "News",
      }]

      setHomePageCategory((prev) => [...initialHomePageCategories, ...data.data, ...endHomePageCategories]);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }
  const [favList, setFavList] = useState([]);

  const getProfile = useCallback(async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData")!);
      const userId = userData?.id;
      const token = localStorage.getItem("token")!;
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle response if necessary
      const { favourite_products } = await response.json();
      setFavList(favourite_products);
      return favourite_products;
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }, []);

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
  }, []);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.banner_main}`}>
        <OwlCarousel className="owl-theme" {...options4}>
          <div className="item">
            <div className="m-0">
              <img src={mainBannerNew} alt="aid" />
            </div>
          </div>
          {heroBannerAds?.length > 0 ? (
            heroBannerAds?.map(({ id, title, url, image }) => {
              return (
                <NavLink to={url} target="_blank" key={id}>
                  <div className="item">
                    <div className="m-0">
                      <img src={image} alt="aid" />
                    </div>
                  </div>
                </NavLink>
              );
            })
          ) : (
            <>
              <div className="item">
                <div className="m-0">
                  <img src={mainBannerNew} alt="aid" />
                </div>
              </div>
            </>
          )}
        </OwlCarousel>
        <Container
          fluid
          style={{ maxWidth: "1500px" }}
          className={`${cx.bannerContainerClasss}`}
        >
          <Row>
            <Col lg={3} sm={4} className="ms-auto cardSet_forMobile">
              <Col md={10} className="ms-auto">
                {!activeAdd && !joinCommunityActive && (
                  <div className={`${cx.banner_boxes}`}>
                    <div className={`${cx.another_banner_boxes}`}>
                      <div className={`${cx.back_btn2}`}>
                        <button
                          className="btn"
                          onClick={() => {
                            setActiveAdd(false);
                            setJoinCommunityActive(false);
                          }}
                        >
                          <FaArrowLeft />
                        </button>
                      </div>
                    </div>
                    <NavLink
                      to={"#"}
                      className={`${cx.banner_attach}`}
                      onClick={() => {
                        setActiveAdd(true);
                      }}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={bagIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Post An Ad</h4>
                            <p>
                              <span>0</span> Ads Posted
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to="#"
                      className={`${cx.banner_attach}`}
                      onClick={() => {
                        setJoinCommunityActive(true);
                      }}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img
                              src={mergeCommunity}
                              alt="bag"
                              style={{ width: "35px" }}
                            />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Join commumity</h4>

                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}

                {activeAdd && (
                  <div
                    className={`${cx.banner_boxes} ${cx.another_banner_boxes}`}
                  >
                    <div className={`${cx.back_btn}`}>
                      <button
                        className="btn"
                        onClick={() => {
                          setActiveAdd(false);
                        }}
                      >
                        <FaArrowLeft />
                      </button>
                    </div>

                    <NavLink
                      to={"#"}
                      onClick={() => {
                        displayMsg("/add-product");
                      }}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={bagIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Product</h4>
                            <p>
                              <span>0</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"#"}
                      onClick={() => {
                        displayMsg("/post-a-job");
                      }}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={certificateIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Job</h4>
                            <p>
                              <span>382</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={"#"}
                      onClick={() => {
                        displayMsg("/post-a-tender");
                      }}
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={communityIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Tender</h4>
                            <p>
                              <span>2M+</span> open vacancies
                            </p>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}
                {joinCommunityActive && (
                  <div
                    className={`${cx.banner_boxes} ${cx.another_banner_boxes}`}
                  >
                    <div className={`${cx.back_btn}`}>
                      <button
                        className="btn"
                        onClick={() => {
                          setJoinCommunityActive(false);
                        }}
                      >
                        <FaArrowLeft />
                      </button>
                    </div>

                    <NavLink
                      to={process.env.REACT_APP_WHATSAPP_LINK}
                      target="_blank"
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={whatsappIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Join on Whatsapp</h4>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                    <NavLink
                      to={process.env.REACT_APP_TELEGRAM_LINK}
                      target="_blank"
                      className={`${cx.banner_attach}`}
                    >
                      <div className={`${cx.banner_FlexArea}`}>
                        <div className={`${cx.left}`}>
                          <div className={`${cx.img_box}`}>
                            <img src={telegramIcon} alt="bag" />
                          </div>
                          <div className={`${cx.box_content}`}>
                            <h4>Join on Telegram</h4>
                          </div>
                        </div>
                        <div className={`${cx.right}`}>
                          <IoMdArrowForward />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )}
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${cx.filter_topSlider} ${st.spb} pb-0`}>
        <Container>
          <div className={`${cx.slideMain_box}`}>
            <div className={`${cx.left}`}>
              <OwlCarousel className="owl-theme" {...options2}>
                {homepageCategory?.map((item: any) => {
                  if (item?.category_name === 'All Categories') {
                    return <div
                      className="item"
                      onClick={() => {
                        navigate("/all-categories", {
                          state: {
                            category: {
                              value: "allcategories",
                              label: "All Categories",
                            },
                          },
                        });
                      }}
                    >
                      <NavLink to={"#"} className={`${cx.boxes}`}>
                        <HiOutlineHome />
                        <p>All Categories</p>
                      </NavLink>
                    </div>
                  }

                  if (item?.category_name === 'Services') {
                    return <div className="item">
                      <NavLink to={"/services"} className={`${cx.boxes}`}>
                        <MdOutlineHomeRepairService />
                        <p>Services</p>
                      </NavLink>
                    </div>
                  }

                  if (item?.category_name === 'Tenders') {
                    return <div className="item">
                      <NavLink to={"/tender"} className={`${cx.boxes}`}>
                        <TbIdBadge />
                        <p>Tenders</p>
                      </NavLink>
                    </div>
                  }

                  if (item?.category_name === 'Jobs') {
                    return <div className="item">
                      <NavLink to={"/job"} className={`${cx.boxes}`}>
                        <HiBuildingOffice2 />
                        <p>Jobs</p>
                      </NavLink>
                    </div>
                  }

                  if (item?.category_name === 'News') {
                    return <div className="item">
                      <NavLink to={"/news"} className={`${cx.boxes}`}>
                        <IoNewspaperOutline />
                        <p>News</p>
                      </NavLink>
                    </div>
                  }
                  return (
                    <>
                      <div className="item">
                        <NavLink
                          to={"#"}
                          className={`${cx.boxes}`}
                          onClick={() => {
                            setTimeout(() => {
                              navigate("/all-categories", {
                                state: {
                                  category: {
                                    value: item?.id,
                                    label: item?.category_name,
                                  },
                                },
                              });
                            }, 400);
                          }}
                        >
                          <img
                            width={30}
                            height={30}
                            src={item.icon_url}
                            alt={item.category_name}
                          />
                          <p>{item.category_name}</p>
                        </NavLink>
                      </div>
                    </>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </Container>
      </section>

      <section className={`${cx.rentList_Sides} ${st.spb}`}>
        <Container>
          {productList.length > 0 && (
            <Row>
              <Col md={12} className="mb-2">

                <div className={`${cx.main_heading}`}>
                  <h2>Popular Suggestion</h2>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            {productList
              ?.filter((item: any) => item)
              ?.map((product: any) => (
                <Col lg={3} md={6} className="mb-4">
                  <ProductCard
                    getProfile={getProfile}
                    favList={favList}
                    product={product}
                    getProductList={getProductList}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </section>

      <section
        className={`${cx.suggestAddsSec_slider} my-4 py-4 mb-5 border-top border-bottom`}
      >
        <Container>
          <Col md={12}>
            <div className="text-center mb-4 pb-1">
              <h2>Suggested Ads</h2>
            </div>
          </Col>

          <Col md={12}>
            <SuggestAdCarousel ads={bottomAds} />
          </Col>
        </Container>
      </section>
    </>
  );
};

export default Home;
