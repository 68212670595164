import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Row } from "react-bootstrap";
import { Container, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ServiceItem } from "../../../constants/Interface";
import { FaHeart } from "react-icons/fa";
import { tokenIsExpired } from "../../../utils/tokenIsExpired";
import { useDispatch } from "react-redux";
import ServiceGetContact from "../../../components/Modals/ServiceGetContact";
import useToast from "../../../utils/useToast";

const ServicesDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { showToast } = useToast();
  const [serviceDetails, setServiceDetails] = useState<ServiceItem>({
    id: 0,
    name: "",
    price: "",
    category: { name: "", id: 0 },
    description: "",
    create_at: "",
    update_at: "",
  });

  async function getServiceDetails() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/services/${params.id}`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      setServiceDetails(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const [favList, setFavList] = useState([]);

  const favorite = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("service", id.toString());
      formData.append("user", JSON.parse(localStorage.getItem("userData")!).id);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/service/fav_service/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        showToast({ type: "error", message: "Something went wrong!" });
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary

      if (response.status === 204 && response.statusText === "No Content") {
        showToast({
          type: "info",
          message: "Service Removed from Favorite",
        });
      }

      if (response.status === 201 && response.statusText === "Created") {
        const data = await response.json();

        showToast({
          type: "success",
          message: data.message ?? "Rating and  Review Submitted Successfully!",
        });
      }
    } catch (error) {
      // Capture the error message to display to the user
      showToast({ type: "error", message: "Something went wrong!" });
      console.error(error);
    } finally {
      getServiceDetails();
      localStorage.getItem("userData")! && getProfile();
    }
  };
  async function getProfile() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${
          JSON.parse(localStorage.getItem("userData")!)?.id
        }`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")!}`,
          },
        }
      );

      // tokenIsExpired(response, toast, dispatch, navigate);

      // Handle response if necessary
      //   const { data,favourite_news,favourite_jobs,favourite_tenders } = await response.json();
      const { favourite_services } = await response.json();
      setFavList(favourite_services);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
    getServiceDetails();
    window.scrollTo(0, 0);
  }, []);

  const [showServiceGetContact, setServiceGetContactShow] = useState(false);
  const handleServiceGetContactClose = () => setServiceGetContactShow(false);
  const handleServiceGetContactShow = () => setServiceGetContactShow(true);

  return (
    <>
      <div className={`${st.header_height}`}></div>

      <section className={`${cx.jobDetail_section} ${cx.pageSpace}`}>
        <Container>
          <Row>
            <Col lg={11} className="m-auto">
              <div className={`${cx.heading_title}`}>
                <div className={`${cx.left_box}`}>
                  <div className={`${cx.badgeBox}`}>
                    {serviceDetails?.category?.name}
                  </div>
                  <h2>{serviceDetails?.name}</h2>
                  {/* <h4>sdsdsdsdsdd sdsdsdd</h4> */}
                </div>

                <div className={`${cx.right_box}`}>
                  <div className={`${cx.getContactBtn} `}>
                    <button
                      className="btn"
                      onClick={handleServiceGetContactShow}
                    >
                      Get Contact
                    </button>
                  </div>
                  <div className={`${cx.heart} `}>
                    <button
                      className={`${
                        favList?.some((item: any) => item.id === +params.id!)
                          ? cx.active
                          : ""
                      }`}
                      onClick={() => {
                        if (!localStorage.getItem("userData")!) {
                          showToast({
                            type: "error",
                            message: "Please login to mark as favorite.",
                          });
                        } else {
                          // setFavorite(true);
                          favorite(+params.id!);
                        }
                      }}
                    >
                      <FaHeart />
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={11} className="m-auto">
              <Row>
                <Col lg={12}>
                  <div className={`${cx.about_job}`}>
                    <div className={`${cx.job_detailsBox}`}>
                      <h2>Service Description</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: serviceDetails.description!,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <ServiceGetContact
        show={showServiceGetContact}
        handleServiceGetContactClose={handleServiceGetContactClose}
      />
    </>
  );
};

export default ServicesDetails;
