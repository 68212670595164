import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown, Form } from "react-bootstrap";
import cx from "./index.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { hamburger, mainHeaderLogo, profile } from "../../../assets/images";
import { RxCross2 } from "react-icons/rx";
import { IoSearchSharp } from "react-icons/io5";
import { Logout } from "../../Modals";
import { useDispatch, useSelector } from "react-redux";
import {
  global_search,
  searchTriggerHandler,
  searchTriggerStatus,
  selectCategoryData,
} from "../../../redux_toolkit/reducer/dataReducer";
import Select from "react-select";
import { useMediaQuery } from "@mui/material";

export default function HeaderSub({ token }: { token?: string }) {
  const [showLogout, setLogoutShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const handleLogoutClose = () => setLogoutShow(false);
  const handleLogoutShow = () => setLogoutShow(true);
  const location = useLocation();

  const navigate = useNavigate();
  const productCategoryList = useSelector(selectCategoryData);
  const searchStatus = useSelector(searchTriggerStatus);
  const dispatch = useDispatch();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState<any>(false);

  const handleDropdownToggle: any = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavLinkClick = () => {
    setDropdownOpen(false);
    // Add any additional logic you want to execute when a NavLink is clicked
  };
  const [isSearchBox, setIsSearchBox] = useState(false);
  function setSearchBoxShow(isSearchBox) {
    setIsSearchBox(!isSearchBox);
  }
  const matches = useMediaQuery("(max-width:520px)");

  useEffect(() => {
    if (isSearchBox) {
      setSearchBoxShow(isSearchBox);
    }
  }, [navigate]);

  const hideSelectBox =
    location?.pathname.includes("/all-categories") ||
    location?.pathname.includes("/job") ||
    location?.pathname.includes("/tender");
  return (
    <>
      <header className={`${cx.main_header}  ${scroll ? cx.affix : ""} `}>
        <Navbar
        // expand="sm"
        >
          <Container className={`${cx.mobile_containerBox}`}>
            <NavLink className="navbar-brand" to={"/"}>
              {/* <h3 className="text-white">Logo</h3> */}
              <img src={mainHeaderLogo} alt="logo" />
              {/* <img src={torchLogo} alt="logo" /> */}
            </NavLink>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse className="justify-content-end" id="navbarScroll">
              {/* <Form className="d-flex col-lg-5 m-auto my-2 my-lg-0 seach_relatedBtns">
                <Form.Control
                  type="search"
                  placeholder="Lorem ispum..."
                  className="me-2"
                  aria-label="Search"
                />
                <button className="btn"><IoSearchSharp /></button>
              </Form> */}
              {/* <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                click
              </Button> */}
              {matches && (
                <div className="mobile_show_seachBtn">
                  {!isSearchBox && (
                    <button
                      className="btn"
                      type="button"
                      onClick={() => {
                        setSearchBoxShow(isSearchBox);
                      }}
                    >
                      {" "}
                      <IoSearchSharp />
                    </button>
                  )}
                  {isSearchBox && (
                    <button
                      className="btn"
                      type="button"
                      onClick={() => {
                        setSearchBoxShow(isSearchBox);
                      }}
                    >
                      {" "}
                      <RxCross2 />
                    </button>
                  )}
                </div>
              )}
              <Form className="d-flex col-xl-5 col-lg-6 m-auto my-2 my-lg-0 my-xl-0 seach_relatedBtns seach_bar_mobile_hide">
                <div
                  className={`position-relative w-100 ${
                    hideSelectBox ? "inner-page-category" : ""
                  }`}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    value={search}
                    onChange={(event: any) => {
                      event.preventDefault();
                      dispatch(global_search(event.target.value));
                      setSearch(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        dispatch(
                          searchTriggerHandler({ trigger: searchStatus })
                        );
                      }
                    }}
                    className="me-2"
                    aria-label="Search"
                  />
                  <button className="btn" type="button">
                    <IoSearchSharp
                      onClick={() => {
                        if (Object.keys(selectedCategory).length) {
                          dispatch(
                            searchTriggerHandler({ trigger: searchStatus })
                          );
                          navigate("/all-categories", {
                            state: {
                              category: selectedCategory,
                              search: search,
                            },
                          });
                        } else {
                          dispatch(
                            searchTriggerHandler({ trigger: searchStatus })
                          );
                          navigate("/all-categories", {
                            state: { search: search },
                          });
                        }
                      }}
                    />
                  </button>
                  {!hideSelectBox && (
                    <Select
                      options={[
                        {
                          value: "tender",
                          label: "Tenders",
                        },
                        {
                          value: "job",
                          label: "Jobs",
                        },
                        {
                          value: "allcategories",
                          label: "All Categories",
                        },
                        ...productCategoryList,
                      ]}
                      onChange={(event: any) => {
                        if (event.value === "tender" || event.value === "job") {
                          navigate(`/${event.value}`);
                        } else {
                          setSelectedCategory(event);
                          navigate("/all-categories", {
                            state: { category: event, search: search },
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </Form>
              <Nav className="align-items-center" navbarScroll>
                <Dropdown
                  show={dropdownOpen}
                  onToggle={handleDropdownToggle}
                  className="ms-2"
                >
                  <Dropdown.Toggle
                    onClick={handleDropdownToggle}
                    id="dropdown-autoclose-true"
                  >
                    <img
                      className={`${cx.hamburger}`}
                      src={hamburger}
                      alt="hamburger"
                    />
                    <img
                      className={`${cx.profile_person}`}
                      src={
                        JSON.parse(localStorage.getItem("userData")!)
                          ?.uploadImages ??
                        JSON.parse(localStorage.getItem("userData")!)
                          ?.sso_profile_image ??
                        profile
                      }
                      alt="profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul>
                      {!token && (
                        <>
                          <li>
                            <NavLink
                              to={"/CreateAccount"}
                              onClick={handleNavLinkClick}
                            >
                              Sign Up
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/login"} onClick={handleNavLinkClick}>
                              Log In
                            </NavLink>
                          </li>
                          <hr className="my-1" />
                        </>
                      )}

                      <li>
                        <NavLink to={"/profile"} onClick={handleNavLinkClick}>
                          Profile Settings
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={'/help-and-support'} onClick={handleNavLinkClick}>Help & Support</NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to={"/contact-us"}
                          onClick={handleNavLinkClick}
                        >
                          Contact Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/offers"} onClick={handleNavLinkClick}>
                          My Offers
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to={'/sold-products'} onClick={handleNavLinkClick}>My Sold Products</NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          to={"/favourites"}
                          onClick={handleNavLinkClick}
                        >
                          My Favourites
                        </NavLink>
                      </li>
                      {token && (
                        <>
                          <hr className="my-1" />
                          <li>
                            <NavLink to={"#"} onClick={handleLogoutShow}>
                              Logout
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <NavLink to={"/contact-us"} className="ms-lg-3 nav-link contact_Btn">Contact</NavLink> */}
              </Nav>
            </Navbar.Collapse>
          </Container>

          {/* <Collapse in={open}>
            <div id="example-collapse-text">
              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
              terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
              labore wes anderson cred nesciunt sapiente ea proident.
            </div>
          </Collapse> */}

          {matches && isSearchBox && (
            <Container className="seach_bar_mobile_show">
              <Form className="d-flex col-12 m-auto my-2 seach_relatedBtns">
                <div
                  className={`position-relative w-100 ${
                    location?.pathname.includes("/all-categories")
                      ? "inner-page-category"
                      : ""
                  }`}
                >
                  <Form.Control
                    type="search"
                    placeholder="Search..."
                    value={search}
                    onChange={(event: any) => {
                      event.preventDefault();
                      dispatch(global_search(event.target.value));
                      setSearch(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        dispatch(
                          searchTriggerHandler({ trigger: searchStatus })
                        );
                      }
                    }}
                    className="me-2"
                    aria-label="Search"
                  />
                  <button className="btn" type="button">
                    <IoSearchSharp
                      onClick={() => {
                        if (Object.keys(selectedCategory).length) {
                          dispatch(
                            searchTriggerHandler({ trigger: searchStatus })
                          );
                          navigate("/all-categories", {
                            state: {
                              category: selectedCategory,
                              search: search,
                            },
                          });
                        } else {
                          dispatch(
                            searchTriggerHandler({ trigger: searchStatus })
                          );
                          navigate("/all-categories", {
                            state: { search: search },
                          });
                        }
                      }}
                    />
                  </button>
                  {!location?.pathname.includes("/all-categories") && (
                    <Select
                      options={[
                        {
                          value: "tender",
                          label: "Tenders",
                        },
                        {
                          value: "job",
                          label: "Jobs",
                        },
                        {
                          value: "allcategories",
                          label: "All Categories",
                        },
                        ...productCategoryList,
                      ]}
                      onChange={(event: any) => {
                        if (event.value === "tender" || event.value === "job") {
                          navigate(`/${event.value}`);
                        } else {
                          setSelectedCategory(event);
                          navigate("/all-categories", {
                            state: { category: event, search: search },
                          });
                        }
                      }}
                    />
                  )}
                </div>
              </Form>
            </Container>
          )}
        </Navbar>
      </header>
      <div className={`${matches && isSearchBox ? cx.showHeight : ""}`}></div>

      <Logout show={showLogout} handleLogoutClose={handleLogoutClose} />
    </>
  );
}
