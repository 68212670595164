import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../redux_store/store";
import * as api from "../../Apis/axiosApis";

interface DataState {
  data: any[];
  subcategoryData: any[];
  dynamicFormEditData: any[];
  tenderData: any[];
  jobData: any[];
  newsData: any[];
  subjectAreaData: any[];
  dynamicFormData: any[];
  newscategoryData: any[];
  regionsData: any[];
  userData: any[];
  contractTypeData: any[];
  educationLevelData: any[];
  jobIndustryData: any[];
  loading: boolean;
  search: string;
  error: string | null;
  searchTrigger: number;
  storedSearch?: any;
  heroBannerAdsIndex?: number;
}

const initialState: DataState = {
  data: [],
  search: "",
  subcategoryData: [],
  dynamicFormEditData: [],
  tenderData: [],
  jobData: [],
  newsData: [],
  newscategoryData: [],
  regionsData: [],
  userData: [],
  contractTypeData: [],
  educationLevelData: [],
  jobIndustryData: [],
  subjectAreaData: [],
  dynamicFormData: [],
  loading: false,
  error: null,
  searchTrigger: 0,
  storedSearch: null,
  heroBannerAdsIndex: 0,
};

export const fetchCategoryData = createAsyncThunk(
  "data/fetchCategoryData",
  async (_, { dispatch }) => {
    try {
      // Call your API function here, for example:
      const data = await api.get("/product/category/");
      return data;
    } catch (error) {
      // Handle errors here
      throw error;
    }
  }
);

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    global_search(state = initialState, action: { payload: string }) {
      state.search = action.payload;
    },
    handleHerroBannerAdsIndex(
      state = initialState,
      action: { payload: number }
    ) {
      state.heroBannerAdsIndex = action.payload;
    },
    searchTriggerHandler(
      state = initialState,
      action: { payload: { trigger: number } }
    ) {
      state.searchTrigger = +action.payload.trigger + 1;
    },
    storedSearchResult(
      state = initialState,
      action: { payload: { storedSearch: any } }
    ) {
      state.storedSearch = {
        ...action?.payload?.storedSearch,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryData.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoryData.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload?.map((item: any) => ({
          label: item?.category_name,
          value: item?.id,
        }));
      })
      .addCase(fetchCategoryData.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  },
});

export const {
  global_search,
  searchTriggerHandler,
  storedSearchResult,
  handleHerroBannerAdsIndex,
} = dataSlice.actions;

export const selectCategoryData = (state: RootState) => state.data.data;
export const globalSearchData = (state: RootState) => state.data.search;
export const searchTriggerStatus = (state: RootState) =>
  state.data.searchTrigger;
export const searchStoredResults = (state: RootState) =>
  state.data.storedSearch;
export const getHeroBannerAdsIndex = (state: RootState) =>
  state.data.heroBannerAdsIndex;

export default dataSlice.reducer;
