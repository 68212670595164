export function getStatusMessage(statusType: string) {
  switch (statusType) {
    case "IP":
      return "progressClr";
    case "EXP":
      return "epxiredClr";
    case "L3D":
      return "lessdayClr";
    default:
      return "unspecifiedClr";
  }
}
