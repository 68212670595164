import React, { useCallback, useEffect, useState } from "react";
import cx from "./index.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import ProductCard from "../../../components/Website/ProductCard";
import FilterSidebar from "../../../components/Website/FilterSidebar";
import { ProductItem } from "../../../constants/Interface";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GiSettingsKnobs } from "react-icons/gi";
import {
  getHeroBannerAdsIndex,
  globalSearchData,
  handleHerroBannerAdsIndex,
  searchStoredResults,
  searchTriggerStatus,
  storedSearchResult,
} from "../../../redux_toolkit/reducer/dataReducer";
import FilterToggle from "../../../components/Modals/FilterToggle";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import totalPagesInPagination from "../../../utils/totalPages";
import SwiperCarousel from "../../../components/Website/SwiperCarousel/SwiperCarousel";
import useToast from "../../../utils/useToast";

interface SearchParams {
  category?: { value: string | null };
  sub_category?: { value: string | null };
  price?: [number | null, number | null];
  [key: string]: any;
}

const AllCategory = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [productList, setProductList] = useState<ProductItem[]>([]);
  const [captureSearchState, setCaptureSearchState] = useState<any>({});
  const [heroBannerAds, setHeroBannerAds] = useState<any[]>([]);

  const [showFilterToggle, setFilterToggleShow] = useState<boolean>(false);
  const handleFilterToggleClose = () => setFilterToggleShow(false);
  const handleFilterToggleShow = () => setFilterToggleShow(true);
  const ActiveHeroBannerIndex = useSelector(getHeroBannerAdsIndex);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(10);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [categoryList, setCategoryList] = useState<any>([
    {
      value: "allcategories",
      label: "All Categories",
    },
  ]);
  const [subCategoryList, setSubCategoryList] = useState<any>([
    {
      value: "allsubcategories",
      label: "All Sub-categories",
    },
  ]);
  const location = useLocation();
  const { register, handleSubmit, reset, setValue, control, getValues, watch } =
    useForm({
      mode: "all",
      reValidateMode: "onSubmit",
    });
  const searchData = useSelector(globalSearchData);
  const searchStatus = useSelector(searchTriggerStatus);
  const storedSearch = useSelector(searchStoredResults);

  const dispatch = useDispatch();

  useEffect(() => {
    getHeroBannerAdsList();
    if (
      location?.state?.category?.value &&
      location?.state?.category?.value !== "allcategories"
    ) {
      reset({ category: location?.state?.category });

      getSubCategoryList({ category_id: location?.state?.category?.value });
    } else {
      reset({
        category: {
          value: "allcategories",
          label: "All Categories",
        },
      });

      getSubCategoryList({ category_id: "allcategory" });
    }
    window.scrollTo(0, 0);
    getProductList();
    getCategoryList();
  }, []);

  async function getHeroBannerAdsList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/hero-banner-ads/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setHeroBannerAds(data);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    if (searchStatus) {
      getProductList();
    }
  }, [searchStatus]);

  async function getCategoryList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/category/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setCategoryList((prev: any) => [
        ...prev,
        ...data?.map((item: any) => ({
          label: item?.category_name,
          value: item?.id,
        })),
      ]);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  async function getSubCategoryList({ category_id }) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/product/sub_category/`
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      setSubCategoryList((prev: any) => [
        ...data
          ?.filter((item) => item?.category?.id === category_id)
          ?.map((item: any) => ({
            label: item?.subcategory_name,
            value: item?.id,
          })),
      ]);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  const getProductList = async (storedSearch?: any) => {
    try {
      if (storedSearch && storedSearch?.storedSearchStatus) {
        const {
          search: searchData,
          storedSearchStatus,
          ...rest
        }: {
          search?: string;
          storedSearchStatus: boolean;
          rest: SearchParams;
        } = storedSearch;

        reset({ ...rest });

        let query_param = Object.entries(rest)
          .filter(([_, values]) => values !== undefined && values !== null)
          .map(([keys, values]) => {
            if (keys === "category") {
              const categoryValue =
                values && (values as { value: string | null }).value;
              return `category_id=${
                categoryValue === "allcategories" ? "" : categoryValue || ""
              }`;
            } else if (keys === "sub_category") {
              const subCategoryValue =
                values && (values as { value: string | null }).value;
              return `sub_category_id=${
                subCategoryValue === "allsubcategories"
                  ? ""
                  : subCategoryValue || ""
              }`;
            } else if (keys === "price") {
              const priceValues = values as [number | null, number | null];
              const priceMin = priceValues[0] !== null ? priceValues[0] : "";
              const priceMax = priceValues[1] !== null ? priceValues[1] : "";
              return `price_min=${priceMin}&price_max=${priceMax}`;
            } else if (Array.isArray(values) && values.length >= 1) {
              return values
                .filter((item: string) => item !== undefined && item !== null)
                .map(
                  (item: any) =>
                    `${keys}=${typeof item === "object" ? item?.value : item}`
                )
                .join("&");
            } else {
              const value =
                values !== undefined && values !== null ? values : "";
              return value.length === 0 ? "" : `${keys}=${value}`;
            }
          })
          .join("&");

        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/product/product_form/?page=${page}&page_size=9&search=${searchData}&${query_param}`
        );
        dispatch(
          storedSearchResult({
            storedSearch: null,
          })
        );

        if (!response.ok) {
          showToast({ type: "error", message: "Something went wrong!" });
          throw new Error("Failed to submit the data. Please try again.");
        }

        // Handle response if necessary
        const { data, count } = await response.json();
        setProductList(data);

        setTotalPages(totalPagesInPagination(count, 9));
      } else {
        let query_param = Object.entries(getValues())
          .filter(([_, values]) => values)
          ?.map(([keys, values]) => {
            if (keys === "category") {
              return `category_id=${
                values.value === "allcategories" ? "" : values.value
              }`;
            } else if (keys === "sub_category") {
              return `sub_category_id=${
                values.value === "allsubcategories" ? "" : values.value
              }`;
            } else if (keys === "price") {
              return `price_min=${values[0]}&price_max=${values[1]}`;
            } else if (values.length >= 1) {
              return values
                .map(
                  (item: any) =>
                    `${keys}=${typeof item === "object" ? item?.value : item}`
                )
                .join("&");
            } else {
              return values
                ? values.length === 0
                  ? ""
                  : `${keys}=${values}`
                : "";
            }
          })
          .join("&");

        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/product/product_form/?page=${page}&page_size=9&search=${searchData}&${query_param}`
        );

        setCaptureSearchState({
          ...getValues(),
          municipality: getValues("municipality")
            ? getValues("municipality")
            : [],
          rating: getValues("rating") ? getValues("rating") : [],
          location: getValues("location") ? getValues("location") : [],
          search: searchData,
          storedSearchStatus: true,
        });

        if (!response.ok) {
          showToast({ type: "error", message: "Something went wrong!" });
          throw new Error("Failed to submit the data. Please try again.");
        }

        // Handle response if necessary
        const { count, data } = await response.json();
        setProductList(data);
        setTotalPages(totalPagesInPagination(count, 9));
      }

      // ...
    } catch (error) {
      showToast({ type: "error", message: "Something went wrong!" });
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const displayMsg = (redirectURL: string) => {
    setTimeout(() => {
      if (localStorage.getItem("token")) {
        navigate(redirectURL);
      } else {
        navigate("/login");
      }
    }, 400);
  };

  useEffect(() => {
    if (storedSearch) {
      setTimeout(() => {
        getProductList(storedSearch);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (page > 1) {
      getProductList(storedSearch);
    }
  }, [page]);
  const [favList, setFavList] = useState([]);

  const getProfile = useCallback(async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData")!);
      const userId = userData?.id;
      const token = localStorage.getItem("token")!;

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/profile/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle response if necessary
      const { favourite_products } = await response.json();
      setFavList(favourite_products);
      return favourite_products;
    } catch (error) {
      showToast({ type: "error", message: `Error fetching profile: ${error}` });
      console.error("Error fetching profile:", error);
    }
  }, []);

  useEffect(() => {
    localStorage.getItem("userData")! && getProfile();
    dispatch(handleHerroBannerAdsIndex(0));
  }, []);

  const handleSlideChange = (swiper) => {
    dispatch(handleHerroBannerAdsIndex(swiper.activeIndex));
  };
  return (
    <>
      <div className={`${st.header_height}`}></div>

      <SwiperCarousel
        handleSlideChange={handleSlideChange}
        heroBannerAds={heroBannerAds}
        ActiveHeroBannerIndex={ActiveHeroBannerIndex}
        upsideDownHeader={getValues("category")?.label ?? "All Categories"}
      />

      <form onSubmit={handleSubmit(getProductList)}>
        <section className={`${cx.select_dropdownMain} ${st.spb} pb-0`}>
          <Container>
            <Row className="align-items-end">
              <Col md={3}>
                {/* <div className={`${cx.select_dropdown_box}`}>
                  <label>Categories</label>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isSearchable={true}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={categoryList}
                        onChange={(event) => {
                          field.onChange(event);
                          getProductList(event);
                          getSubCategoryList({ category_id: event?.value });
                        }}
                      />
                    )}
                  />
                </div>
                <div className={`${cx.select_dropdown_box} mt-3`}>
                  <label>Sub-categories</label>
                  <Controller
                    name="sub_category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isSearchable={true}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={subCategoryList}
                        onChange={(event) => {
                          field.onChange(event);
                          getProductList(event);
                        }}
                      />
                    )}
                  />
                </div> */}
              </Col>
              {/* <Col md={6}>

              </Col> */}
              <Col md={9} className={`${cx.orderStyle}`}>
                <div className={`${cx.box_flexArea}`}>
                  {/* <div className={`${cx.checkBox_customize}`}>
                    <Form className="d-flex forMobile">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="For Rent"
                      />
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch2"
                        label="For Sale"
                      />
                    </Form>
                  </div> */}
                  <div className={`${cx.post_attachBtn}`}>
                    <button
                      onClick={() => {
                        displayMsg("/add-product");
                      }}
                      className="btn"
                    >
                      <span> </span>
                      <span> </span>
                      <span> </span>
                      <span> </span>
                      Post An Ad
                    </button>

                    <div className={`${cx.filter_mobile}`}>
                      <button
                        className="filter_btn"
                        onClick={handleFilterToggleShow}
                      >
                        <GiSettingsKnobs />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${cx.rentList_Sides} ${st.spb} pt-4`}>
          <Container>
            <Row>
              <Col lg={3} md={4} className={`${cx.filterHide_onMobile} `}>
                <FilterSidebar
                  register={register}
                  control={control}
                  onSubmit={getProductList}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  dispatch={dispatch}
                  storedSearch={storedSearch}
                  categoryList={categoryList}
                  subCategoryList={subCategoryList}
                  getSubCategoryList={getSubCategoryList}
                />
              </Col>

              <Col lg={9} md={8}>
                <Row style={{ marginTop: "7px" }}>
                  {productList
                    ?.filter((item: any) => item)
                    ?.map((product: any, index: number) => (
                      <Col lg={4} md={6} className="mb-4" key={product?.id}>
                        <ProductCard
                          product={product}
                          getProfile={getProfile}
                          favList={favList}
                          getProductList={getProductList}
                          captureSearchState={captureSearchState}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={3}></Col>
              <Col md={9}>
                {/* <div className={`${cx.paginationBox} mt-4`}>
                  <Pagination>
                    <Pagination.First />
                    <Pagination.Prev />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item disabled>{4}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{20}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </div> */}
                <div className={`${cx.paginationBox} mt-4`}>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handleChange}
                      variant="outlined"
                      shape="rounded"
                      showFirstButton
                      showLastButton
                    />
                  </Stack>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </form>

      {showFilterToggle && (
        <FilterToggle
          show={showFilterToggle}
          handleFilterToggleClose={handleFilterToggleClose}
          register={register}
          control={control}
          onSubmit={getProductList}
          setValue={setValue}
          watch={watch}
          dispatch={dispatch}
          getValues={getValues}
          storedSearch={storedSearch}
          categoryList={categoryList}
          subCategoryList={subCategoryList}
          getSubCategoryList={getSubCategoryList}
        />
      )}
    </>
  );
};

export default AllCategory;
