import React from "react";
import { NavLink, useLocation } from "react-router-dom"; // Make sure to import any necessary dependencies
import {
  FaStar,
  FaClock,
  FaEye,
  FaClipboardCheck,
  FaEdit,
} from "react-icons/fa"; // Import necessary icons
import { product } from "../../../assets/images";
import cx from "./index.module.scss";
import { IoLocationSharp } from "react-icons/io5";
import { Row, Col } from "react-bootstrap";
import { ProductItem } from "../../../constants/Interface";
import { MdDelete } from "react-icons/md";

const ratings = [1, 2, 3, 4, 5];

const OffersCard = ({
  productData,
  getProductList,
  markAsSold,
  pathname,
  handleDelete,
}: {
  pathname:string;
  productData: ProductItem;
  getProductList: any;
  markAsSold: any;
  handleDelete: any;
}) => {
  const {
    id,
    images,
    user,
    title,
    price = 0,
    description,
    location: productLocation,
    views,
    created_at,
    sold,
    avg_rating,
    product_images,
  } = productData;

  return (
    <NavLink to={"#"} className={`${cx.offer_main_box} mb-4`}>
      <Row className="align-items-center">
        <Col lg={5} md={6}>
          <div className={`${cx.offer_imgBox}`}>
            <img
              src={
                product_images.length > 0
                  ? product_images[0].image_url
                  : product
              }
              alt="offers"
            />
          </div>
        </Col>
        <Col lg={7} md={6}>
          <div className={`${cx.contentArea}`}>
            <div className={`${cx.timing_heart}`}>
              <div className={`${cx.time}`}>
                <FaClock />
                {new Date(created_at).toDateString()}
              </div>
            </div>
            <h3>{title}</h3>
            <div className={`${cx.headLine}`}>
              <h4>${price ? +price?.toFixed(2) : 0} USD</h4>
              <div className={`${cx.rating}`}>
                {ratings
                  .filter((item: number) => item <= avg_rating)
                  .map((item: number) => (
                    <FaStar />
                  ))}
              </div>
            </div>
            <p>{description}</p>
            <div className={`${cx.location_views}`}>
              <div className={`${cx.location}`}>
                <IoLocationSharp /> {productLocation}
              </div>
              <div className={`${cx.views}`}>
                <FaEye /> {views}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {!sold && (
        <div className={`${cx.mark_sold}`}>
          <NavLink to={`/edit-product/${id}`} className="btn">
            <FaEdit /> Edit
          </NavLink>
          {/* <button
            type="button"

          >

          </button> */}
          <button
            type="button"
            className="btn"
            onClick={() => handleDelete(id)}
          >
            <MdDelete /> Delete
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => {
              markAsSold(id);
            }}
          >
            <FaClipboardCheck /> Mark as Sold
          </button>
        </div>
      )}
    </NavLink>
  );
};

export default OffersCard;
