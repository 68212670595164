import React from "react";
import { Outlet } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import cx from "./index.module.scss";
import Footer from "../Footer";
import HeaderSub from "../HeaderSub";

export default function Navigation({ token }: { token?: string }) {
  return (
    <>
      <div className={`${st.font} ${cx.websiteWrapper}`}>
        {/* {location.pathname === '/' ?<HeaderSub />:<HeaderSub />} */}
        <HeaderSub token={token} />
        <Outlet />
        <Footer />
      </div>
    </>
  );
}
