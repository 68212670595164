import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";

const Checkbox = ({ value, register, name, onSubmit }: any) => {
  return (
    <>
      <input
        type="checkbox"
        {...register(name, {
          onChange: onSubmit,
        })}
        value={value}
      />
      <span className={`${st.checkmark}`}></span>
    </>
  );
};

export default Checkbox;
